import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { Squash } from "hamburger-react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaApple, FaDiscord, FaFacebook, FaFacebookF, FaInstagram, FaSpotify, FaYoutube, IconName } from "react-icons/fa";
import axiosInstance from "api/axiosService";

function Header(props) {
  const { t, i18n } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const [language, setCurrentLanguage] = useState('vi');
  const [settingInfo, setSettingInfo] = useState({})
  const [projectDetail, setProjectDetail] = useState([]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const ToggleLanguage = (languague) => {
    setCurrentLanguage(languague)
    changeLanguage(languague)
  }

  useEffect(() => {
    fetchData();
    fetchProjectData()
  }, []);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get('/api/setting/info');
      setSettingInfo(response.data.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
      // Xử lý lỗi tại đây
    }
  };

  const fetchProjectData = async () => {
    try {
      const projectList = await axiosInstance.get(`api/project/list`);
      const info = projectList.data.data
      console.log(info)
      setProjectDetail(info)
    } catch (error) {
      console.error('Error fetching data: ', error);
      // Xử lý lỗi tại đây
    }
  };

  return (
    <div className="header-app">
      <div className="logo" style={{ alignItems: 'center' }}>
        <FaFacebook onClick={() => window.open(settingInfo?.facebook_link)} style={{ color: 'white', pointerEvents: 'cursor', fontSize: '26px', margin: '0 7px 0 7px' }} />
        <FaYoutube onClick={() => window.open(settingInfo?.youtube_link)} style={{ color: 'white', pointerEvents: 'cursor', fontSize: '26px', margin: '0 7px 0 7px' }} />
        <FaInstagram onClick={() => window.open(settingInfo?.instagram_link)} style={{ color: 'white', pointerEvents: 'cursor', fontSize: '26px', margin: '0 7px 0 7px' }} />
        <FaSpotify onClick={() => window.open(settingInfo?.spotify_link)} style={{ color: 'white', pointerEvents: 'cursor', fontSize: '26px', margin: '0 7px 0 7px' }} />
        <FaApple onClick={() => window.open(settingInfo?.apple_link)} style={{ color: 'white', pointerEvents: 'cursor', fontSize: '26px', margin: '0 7px 0 7px' }} />
        <FaDiscord onClick={() => window.open(settingInfo?.discord_link)} style={{ color: 'white', pointerEvents: 'cursor', fontSize: '26px', margin: '0 7px 0 7px' }} />
      </div>
      <div className="btn-menu">
        <div className="change-language">
          <span style={{ color: 'white', marginRight: '25px', textDecoration: language == 'en' ? 'underline' : '' }} onClick={() => ToggleLanguage('en')} >ENG</span>
          <span style={{ color: 'white', textDecoration: language == 'vi' ? 'underline' : '' }} onClick={() => ToggleLanguage('vi')} >VN</span>
        </div>
        <Squash toggled={isOpen} toggle={setOpen} color="#fff" size={30} />
      </div>
      <div className={`menu-header ${isOpen ? "show-menu" : ""}`}>
        <div className="list-menu">
          <Link to="/" className="menu-item" onClick={() => setOpen(false)}>
            {t("HOME")}
          </Link>
          <Link
            to="/about"
            className="menu-item"
            onClick={() => setOpen(false)}
          >
            {t("ABOUT")}
          </Link>
          <Link
            to="/artists"
            className="menu-item"
            onClick={() => setOpen(false)}
          >
            {t("ARTISTS")}
          </Link>
          <Link
            to="/discography"
            className="menu-item"
            onClick={() => setOpen(false)}
          >
            {t("DISCOGRAPHY")}
          </Link>
          {
            projectDetail?.map(ele => <Link
              to={`/${ele.name}`}
              key={ele._id}
              className="menu-item"
              onClick={() => setOpen(false)}
            >
              {ele.name}
            </Link>)
          }
          <Link
            to="/project"
            className="menu-item"
            onClick={() => setOpen(false)}
          >
            {t("PROJECT")}
          </Link>
          <Link
            to="/contact"
            className="menu-item"
            onClick={() => setOpen(false)}
          >
            {t("CONTACT")}
          </Link>
          {/* <div className="translate">
            <div onClick={() => i18n.changeLanguage("en")}>EN</div>
            <div className="dash"></div>
            <div onClick={() => i18n.changeLanguage("vi")}>VIE</div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Header;
