import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import CCarousel from "Common/CCarousel";
import { useNavigate } from "react-router-dom";
import BINZ from "../../assets/images/ARTIST/Binz-real.jpg";
import SOOBIN from "../../assets/images/ARTIST/SOOBIN-real.jpg";
import RHYMASTIC from "../../assets/images/ARTIST/Rhymastic-real.jpg";
import TOULIVER from "../../assets/images/ARTIST/Touliver-real.jpeg";
import SLIMV from "../../assets/images/ARTIST/Slimv-real.jpeg";
import TINLE from "../../assets/images/ARTIST/TINLE-real.jpg";
import GONZO from "../../assets/images/ARTIST/GONZO-real.jpg";
import TYPH from "../../assets/images/ARTIST/16Typh-real.jpg";
import MastaL from "../../assets/images/ARTIST/Mastal-real.jpeg";
import axios from '../../api/axiosService';

function Artists(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [size, setSize] = useState(window.innerWidth);
  const [listArtist, setListArtist] = useState([{
    key: "first"
  }]);
  const reportWindowSize = () => {
    setSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", reportWindowSize);
    return () => {
      window.removeEventListener("resize", reportWindowSize);
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/artist/list?limit=1000');
      let tempArtists = [{
        key: "first"
      }]
      response.data.data.forEach(ele => {
        tempArtists.push({
          key: ele.name,
          image: ele.image
        })
      })
      console.log('tempArtists', tempArtists)
      setListArtist(tempArtists);
    } catch (error) {
      console.error('Error fetching data: ', error);
      // Xử lý lỗi tại đây
    }
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <div className="artists-ssg">
      {size > 700 ? (
        <div className="artists-ssg" style={{ flexDirection: 'column', height: '100vh' }}>
          <div className="artists-ssg" style={{ height: '100vh' }}>
            {
              listArtist.length > 1 &&
              <CCarousel>
                {listArtist?.map(value => {
                  if (value.key === "first")
                    return (
                      <div key="first" className="main-item first">
                        <div className="content content-space">SS LABEL</div>
                        <div className="title">ARTISTS</div>
                      </div>
                    );
                  return (
                    <div
                      key={value.key}
                      className="main-item"
                      onClick={() => navigate(`${value.key.toLocaleLowerCase()}`)}
                    >
                      <img loading="lazy" src={value.image} alt={value.key} />
                      <div className="name">{capitalizeFirstLetter(value.key)}</div>
                    </div>
                  );
                })}
              </CCarousel>
            }
          </div>
          {/* <div className="artists-ssg"  style={{height: '100vh'}}>
            <CCarousel>
              {DATASS.map(value => {
                if (value.key === "first")
                  return (
                    <div key="first" className="main-item first">
                      <div className="title">{t("ARTIST")}</div>
                      <div className="content">SS LABEL</div>
                    </div>
                  );
                return (
                  <div
                    key={value.key}
                    className="main-item"
                    onClick={() => navigate(`${value.key.toLocaleLowerCase()}`)}
                  >
                    <img src={value.image} alt={value.key} />
                    <div className="name">{value.key}</div>
                  </div>
                );
              })}
            </CCarousel>
          </div> */}

        </div>
      ) : (
        <div className="content-mobile">
          {listArtist.length > 1 && listArtist.map(value => {
            if (value.key === "first")
              return (
                <div key="first" className="main-item first">
                  <div className="content">SS LABEL ARTISTS</div>
                </div>
              );
            return (
              <div
                key={value.key}
                className="main-item"
                onClick={() => navigate(`${value.key}`)}
              >
                <img loading="lazy" src={value.image} alt={value.key} />
                <div className="name">{value.key}</div>
              </div>
            );
          })}
          {/* {DATASS.map(value => {
            if (value.key === "first")
              return (
                <div key="first" className="main-item first">
                  <div className="title">{t("ARTIST")}</div>
                  <div className="content">SPACESPEAKERS</div>
                  <div className="content">OG</div>
                </div>
              );
            return (
              <div
                key={value.key}
                className="main-item"
                onClick={() => navigate(`${value.key.toLocaleLowerCase()}`)}
              >
                <img src={value.image} alt={value.key} />
                <div className="name">{value.key}</div>
              </div>
            );
          })} */}
        </div>
      )}
    </div>
  );
}

const DATA = [
  {
    key: "first"
  },
  {
    key: "TOULIVER",
    image: TOULIVER
  },
  {
    key: "BINZ",
    image: BINZ
  },
  {
    key: "SOOBIN",
    image: SOOBIN
  },
  {
    key: "RHYMASTIC",
    image: RHYMASTIC
  },
  {
    key: "SLIMV",
    image: SLIMV
  },
  {
    key: "16 TYPH",
    image: TYPH
  },
  {
    key: "GONZO",
    image: GONZO
  },
  {
    key: "TINLE",
    image: TINLE
  },
  {
    key: "MastaL",
    image: MastaL
  }
];

const DATASS = [
  {
    key: "first"
  },
  {
    key: "16 TYPH",
    image: TYPH
  },
  {
    key: "GONZO",
    image: GONZO
  },
  {
    key: "TINLE",
    image: TINLE
  },
  {
    key: "MastaL",
    image: MastaL
  }
];

export default Artists;
