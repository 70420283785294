import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";

function Contact(props) {
  const { t } = useTranslation();
  return (
    <div className="contact-ssg">
      <div className="title tile-mt">{t("CONTACT")}</div>
      <img
        className="back-ground"
        alt="bg"
        src="https://kenh14cdn.com/thumb_w/660/203336854389633024/2021/10/3/01-1633273554386819705506.jpg"
      />
      <div className="content tile-mt">
        <div className="des" style={{ color: "#BDBEBF" }}>
        {t("Địa Chỉ")}
          <br />
         {t("Số Điện Thoại")} : 092 263 36 36
          <br />
          Email: booking@spacespeakers.vn
          <br />
          {t("recruitEmail")}: hr@spacespeakers.vn
        </div>
      </div>
      {/* <div className="title tile-mt">{t("COLLABORATION")}</div>
      <div className="content tile-mt">
        <div className="des" style={{ color: "#BDBEBF" }}>
          {t("Update Content...")}
        </div>
      </div>
      <div className="tile-mt">
        <a className="button" href="mailto:booking@spacespeakers.vn">
          {t("SUBMIT YOUR CV")}
        </a>
      </div> */}
    </div>
  );
}

export default Contact;
