import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import axiosInstance from "api/axiosService";

function Project(props) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [listNews, setListNews] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axiosInstance.get('/api/news/list?limit=100');
      let tempArtists = []
      response.data.data.forEach(ele => {
        tempArtists.push({
          title_VN: ele.title_VN,
          title_ENG: ele.title_ENG,
          content_ENG: ele.content_ENG,
          content_VN: ele.content_VN,
          url: ele.url,
          content: ele.content,
          image: ele.image
        })
      })
      console.log('tempArtists', tempArtists)
      setListNews(tempArtists);
    } catch (error) {
      console.error('Error fetching data: ', error);
      // Xử lý lỗi tại đây
    }
  };

  return (
    <div className="project">
      <div className="main">
        <div className="title">{t("DỰ ÁN")}</div>
        <div className="image-project">
          <img alt="ssg" src="/images/image-project.jpg" />
        </div>
        <div className="topic">
          <div className="title">{t("PROJECT-1")}</div>
          <div className="content">
            {t(
              "PROJECT-2"
            )}
          </div>
        </div>
        <div className="list-project">
          <div className="title">{t("DANH SÁCH DỰ ÁN")}</div>
          <div className="list">
            {
              listNews.map(ele => <div className="item-project" onClick={() => window.open(ele.url, '_blank')}>
                <img className="new-image" alt="ssg" src={ele.image} />
                <div className="title-item">
                  <h1 className="project-title">
                    {i18n.language == 'vi' ? ele.title_VN : ele.title_ENG}
                  </h1>
                  <div className="brief-intro">
                  {i18n.language == 'vi' ? ele.content_VN : ele.content_ENG}
                  </div>
                </div>
              </div>)
            }
            <div className="item-project" onClick={() => navigate("dac-biet")}>
              <img className="new-image" alt="ssg" src="https://toquoc.mediacdn.vn/280518851207290880/2022/10/29/layer-2-1666976495891778677268.jpg" />
              <div className="title-item">
                <h1 className="project-title">
                  Số Đặc Biệt
                </h1>
                <div className="brief-intro">
                  {t("Đặc Biệt-1")}
                </div>
              </div>
            </div>
            <div className="item-project" onClick={() => navigate("mv-doi-that-rap-that")}>
              <img className="new-image" alt="ssg" src="https://vtv1.mediacdn.vn/thumb_w/650/562122370168008704/2023/6/3/photo1685757404059-16857574053231848538925.png" />
              <div className="title-item">
                <h1 className="project-title">
                  MV Đời Thật Rap Thật
                </h1>
                <div className="brief-intro">
                  {t("Đời Thật Rap Thật-1")}
                </div>
              </div>
            </div>
            <div
              className="item-project"
              onClick={() => navigate("space-jam-1")}
            >
              <img className="new-image" alt="ssg" src="https://images2.thanhnien.vn/528068263637045248/2023/5/9/lcnl3866-1683617931143907484992.jpg" />
              <div className="title-item">
                <h1 className="project-title">
                  SPACE JAM
                </h1>
                <div className="brief-intro">
                  {t("Nằm trong hệ sinh thái")}
                </div>
              </div>
            </div>
            <div
              className="item-project"
              onClick={() => navigate("space-date")}
            >
              <img className="new-image" alt="ssg" src="https://toquoc.mediacdn.vn/280518851207290880/2023/5/22/nguyen-team-di-vao-het-16847227927401505697951.jpg" />
              <div className="title-item">
                <h1 className="project-title">
                  Space Date
                </h1>
                <div className="brief-intro">
                  {t("Space Date-1")}
                </div>
              </div>
            </div>
            <div className="item-project" onClick={() => navigate("mvdear")}>
              <img className="new-image" alt="ssg" src="https://yt3.googleusercontent.com/RVJNlN2Z7gj6A1c9Y9G8COj2LacfICY9zw1i_pxPN02QtvchDuDFR8twmjGvTUC-g82aVzfN=s900-c-k-c0x00ffffff-no-rj" />
              <div className="title-item">
                <h1 className="project-title">
                  MV Dear
                </h1>
                <div className="brief-intro">
                  {t("MV Dear-1")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Project;
