import CCarousel2 from "Common/CCarousel2";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

function SSGVNA(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="ssg-vna">
      <main>
        <img alt="ssg" src="https://vtv1.mediacdn.vn/thumb_w/640/562122370168008704/2023/6/3/screen-shot-2023-06-03-at-085741-16857574762751337641155.png" />
        <div className="topic">
          <desc>{t("DỰ ÁN")}</desc>
          <div className="title">{t("Đời thật rap thật: 16 Typh trở lại đường đua rap Việt với sản phẩm bớt 'gắt gỏng'")}</div>
          <div className="content">
            <div>{t("Đời Thật Rap Thật-1")}</div>
            <br/>
            <div>{t("Đời Thật Rap Thật-2")}</div>
            <br/>
            <div>{t("Đời Thật Rap Thật-3")}</div>
            <br/>
            <div>{t("Đời Thật Rap Thật-4")}</div>
            <br/>
            <div>{t("Đời Thật Rap Thật-5")}</div>
            <br/>
            <div>{t("Đời Thật Rap Thật-6")}</div>
            <br/>
            <div>{t("Đời Thật Rap Thật-7")}</div>
            <br/>
            <div>{t("Đời Thật Rap Thật-8")}</div>
            <br/>
            <div>{t("Đời Thật Rap Thật-9")}</div>
            <br/>
            <div>{t("Đời Thật Rap Thật-10")}</div>
            <br/>
            <div>{t("Đời Thật Rap Thật-11")}</div>
            <br/>
            <div>{t("Đời Thật Rap Thật-12")}</div>
          </div>
        </div>
        {/* <div className="list-image">
          <div className="title-image">{t("HÌNH ẢNH")}</div>
          <CCarousel2 items={items} />
        </div> */}
        {/* <div className="list-project">
          <div className="title">{t("DANH SÁCH DỰ ÁN")}</div>
          <div className="list">
            <div
              className="item-project"
              onClick={() => navigate("/project/DoiThatRapThat")}
            >
              <img alt="ssg" src="https://vtv1.mediacdn.vn/thumb_w/650/562122370168008704/2023/6/3/photo1685757404059-16857574053231848538925.png" />
              <div className="title-item">MV Đời Thật Rap Thật</div>
            </div>
            <div
              className="item-project"
              onClick={() => navigate("/project/space-jam-1")}
            >
              <img alt="ssg" src="/images/PROJECT/space-jam.jpg" />
              <div className="title-item">SPACE JAM #1</div>
            </div>
            <div
              className="item-project"
              onClick={() => navigate("/project/muzik-dap-dich")}
            >
              <img alt="ssg" src="/images/PROJECT/muzik-dap-dich.jpg" />
              <div className="title-item">MUZIK DẬP DỊCH</div>
            </div>
            <div
              className="item-project"
              onClick={() => navigate("/project/sstv")}
            >
              <img alt="ssg" src="/images/PROJECT/sstv.jpg" />
              <div className="title-item">SSTV</div>
            </div>
          </div>
        </div> */}
      </main>
    </div>
  );
}

const items = [
  {
    link: "",
    img: "/images/PROJECT/SSG-VNA/1.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SSG-VNA/2.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SSG-VNA/3.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SSG-VNA/4.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SSG-VNA/5.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SSG-VNA/6.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SSG-VNA/7.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SSG-VNA/8.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SSG-VNA/9.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SSG-VNA/10.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SSG-VNA/11.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SSG-VNA/12.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SSG-VNA/13.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SSG-VNA/14.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SSG-VNA/15.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SSG-VNA/16.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SSG-VNA/17.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SSG-VNA/18.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SSG-VNA/19.jpeg"
  }
];
export default SSGVNA;
