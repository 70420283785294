import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { content } from "./content";
import ArtistList from "Components/ArtistList";
import CCarousel from "Common/CCarousel";
import _ from "lodash";
import { Carousel } from 'antd';
import axiosInstance from "api/axiosService";

function About(props) {
  const { t , i18n} = useTranslation();
  const [settingInfo, setSettingInfo] = useState({})

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get('/api/setting/info');
      setSettingInfo(response.data.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
      // Xử lý lỗi tại đây
    }
  };

  const contentStyle = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  return (
    <div className="about-ssg">
      <div className="title tile-mt">          
        <img src={settingInfo?.logo} width={200} alt="logo" />
      </div>
      <img className="back-ground" alt="bg" src={settingInfo?.img_about} />
      <div className="content" style={{whiteSpace: 'pre-wrap', marginTop: 30}}>
          {i18n.language == 'vi' ? settingInfo.content_VN : settingInfo.content_ENG}
      </div>
      <div className="title tile-mt">{t("ARTIST")}</div>
      <ArtistList />
      <div className="list-product">
        <div className="title">{t("SẢN PHẨM CHUNG")}</div>
        {/* <CCarousel>
          {contentsData.map(value => (
            <a
              key={value.id}
              className="thumbnail-item"
              href={value.video}
              target="_blank"
              rel="noreferrer"
            >
              <img
                // onClick={() => setVideo(value)}
                src={_.get(value, "image", "")}
                alt={_.get(value, "image")}
              />
            </a>
          ))}
        </CCarousel> */}
         <Carousel afterChange={onChange} autoplay style={{display:'flex'}}>
          <div className="carousel-item" style={{display:'flex'}}>
          <a  style={contentStyle}
              key={contentsData[0].id}
              className="thumbnail-item"
              href={contentsData[0].video}
              target="_blank"
              rel="noreferrer"
            >
              <img
                // onClick={() => setVideo(value)}
                src={_.get(contentsData[0], "image", "")}
                alt={_.get(contentsData[0], "image")}
              />
            </a>
            <a  style={contentStyle}
              key={contentsData[1].id}
              className="thumbnail-item"
              href={contentsData[1].video}
              target="_blank"
              rel="noreferrer"
            >
              <img
                // onClick={() => setVideo(value)}
                src={_.get(contentsData[1], "image", "")}
                alt={_.get(contentsData[1], "image")}
              />
            </a>
            <a  style={contentStyle}
              key={contentsData[2].id}
              className="thumbnail-item"
              href={contentsData[2].video}
              target="_blank"
              rel="noreferrer"
            >
              <img
                // onClick={() => setVideo(value)}
                src={_.get(contentsData[2], "image", "")}
                alt={_.get(contentsData[2], "image")}
              />
            </a>
          </div>
          <div className="carousel-item" style={{display:'flex'}}>
          <a  style={contentStyle}
              key={contentsData[3].id}
              className="thumbnail-item"
              href={contentsData[3].video}
              target="_blank"
              rel="noreferrer"
            >
              <img
                // onClick={() => setVideo(value)}
                src={_.get(contentsData[3], "image", "")}
                alt={_.get(contentsData[3], "image")}
              />
            </a>
            <a  style={contentStyle}
              key={contentsData[4].id}
              className="thumbnail-item"
              href={contentsData[4].video}
              target="_blank"
              rel="noreferrer"
            >
              <img
                // onClick={() => setVideo(value)}
                src={_.get(contentsData[4], "image", "")}
                alt={_.get(contentsData[4], "image")}
              />
            </a>
            <a  style={contentStyle}
              key={contentsData[5].id}
              className="thumbnail-item"
              href={contentsData[5].video}
              target="_blank"
              rel="noreferrer"
            >
              <img
                // onClick={() => setVideo(value)}
                src={_.get(contentsData[5], "image", "")}
                alt={_.get(contentsData[5], "image")}
              />
            </a>
          </div>
        </Carousel>
      </div>
    </div>
  );
}

const contentsData = [
  {
    id: 1,
    image:
      "https://i.ytimg.com/vi/N4a9Db9_ijc/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAOBuNrIVVV61sBbiP9QYPW_Pnf6A",
    video:
      "https://www.youtube.com/watch?v=N4a9Db9_ijc&ab_channel=SpaceSpeakers"
  },
  {
    id: 2,
    image:
      "https://i.ytimg.com/vi/uwlGzAprCrw/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBGRCNDjpKRh8c08j1IrHea-DbdDQ",
    video:
      "https://www.youtube.com/watch?v=uwlGzAprCrw&ab_channel=SpaceSpeakers"
  },
  {
    id: 3,
    image:
      "https://i.ytimg.com/vi/2hKDGAcHw5M/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD8iFFqCZM09YVeVYx8sQmLSlAsBg",
    video:
      "https://www.youtube.com/watch?v=2hKDGAcHw5M&t=4s&ab_channel=SpaceSpeakersTV"
  },
  {
    id: 4,
    image:
      "https://i.ytimg.com/vi/c-mILo5qZjc/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBLRKCrpMoiZXCrgyw7iRylqM_mSA",
    video:
      "https://www.youtube.com/watch?v=c-mILo5qZjc&t=7s&ab_channel=SpaceSpeakersTV"
  },
  {
    id: 5,
    image:
      "https://i.ytimg.com/vi/Ab_2vtnESUQ/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLACBIKYNARHyUbhv42WezF_FTnbvA",
    video:
      "https://www.youtube.com/watch?v=Ab_2vtnESUQ&ab_channel=SpaceSpeakers"
  },
  {
    id: 6,
    image:
      "https://i.ytimg.com/vi/uQHHzgROvjw/maxresdefault.jpg",
    video:
      "https://www.youtube.com/watch?v=uQHHzgROvjw"
  }
];

export default About;
