import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import About from "Components/About";
import Artists from "Components/Artists";
import ArtistsDetail from "Components/Artists/ArtistsDetail";
import Contact from "Components/Contact";
import Discography from "Components/Discography";
import DiscographyDetail from "Components/DiscographyDetail";
import RADAR from "Components/55RADAR/index";
import Footer from "Components/Footer";
import Header from "Components/Header";
import Home from "Components/Home";
import Label from "Components/Label";
import Project from "Components/Project";
import SD from "Components/Project/SpaceDate";
import SPACEJAM from "Components/Project/SPACEJAM";
import DTRT from "Components/Project/DoiThatRapThat";
import MVDEAR from "Components/Project/MVDear";
import BinzSoobin from "Components/Project/BinZ-Soobin";
import DACBIET from "Components/Project/DacBiet";
import "i18n";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import useWindowDimensions from "Hook/useWindowDimensions.jsx";
import HomeMobile from "Components/Home/HomeMobile";

function App() {
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    uri: "https://api.appfast.io/v8",
  });

  const { height, width } = useWindowDimensions();

  return (
    <ApolloProvider client={client}>
      <Router>
        <div className="App">
          <Header />
          <Footer />
          <Routes>
            <Route path="/">
              <Route index element={width < 991 ? <HomeMobile /> : <Home />} />
              <Route path="about" element={<About />} />
              <Route path="artists">
                <Route index element={<Artists />} />
                <Route path=":name" index element={<ArtistsDetail />} />
              </Route>
              <Route path="discography">
                <Route index element={<Discography />} />
                <Route path="detail/:id" element={<DiscographyDetail />} />
              </Route>
              <Route path="/:project" element={<RADAR />} />
              <Route path="spacespeakers-label" element={<Label />} />
              {/* <Route path="store" element={<Store />} /> */}
              <Route path="project">
                <Route index element={<Project />} />
                <Route path="mv-doi-that-rap-that" element={<DTRT />} />
                <Route path="space-jam-1" element={<SPACEJAM />} />
                <Route path="space-date" element={<SD />} />
                <Route path="mvdear" element={<MVDEAR />} />
                <Route path="dac-biet" element={<DACBIET />} />
                <Route path="binz-soobin" element={<BinzSoobin />} />
              </Route>
              <Route path="contact" element={<Contact />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </ApolloProvider>
  );
}

export default App;
