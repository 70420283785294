import { gql } from '@apollo/client';
 
 const CONTENTS = gql`
  query contentFilter($where: JSON!, $limit: Int, $start: Int) {
    contentFilter(where: $where, limit: $limit, start: $start) {
      id
      _id
      title
      description
      link
      active
      extra_info
      content_tags
      images
      type
      source_id {
        type
      }
    }
  }
`;

export default CONTENTS