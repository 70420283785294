import React, { useEffect, useRef, useState } from 'react'
import { FaPause, FaPlay, FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import { videoArray } from './config';
import './styles.scss';
import axiosInstance from 'api/axiosService';

function HomeMobile() {
  const [isMuted, setIsMuted] = useState(false);
  const [settingInfo, setSettingInfo] = useState({})

  const vidRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get('/api/setting/info');
      setSettingInfo(response.data.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
      // Xử lý lỗi tại đây
    }
  };

  function convertToEmbedUrl(youtubeUrl) {
    if(youtubeUrl) {
      const url = new URL(youtubeUrl);
      const videoId = url.searchParams.get('v');
      if (videoId) {
          return `https://youtube.com/embed/${videoId}?autoplay=1&mute=1&enablejsapi=1&loop=1&playlist=${videoId}`;
      } else {
          return 'Invalid YouTube URL';
      }
    }
}

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="logo">
          <img src={settingInfo?.logo} width={100} alt="logo" />
        </div>
      </div>
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: '500px',
        width: '100%',
        overflow: 'hidden',
        pointerEvents: 'none'
      }}>
        <iframe
          id="homepage-video-mobile"
          ref={vidRef}
          style={{ height: '500px', width: '95%' }}
          title='Youtube player'
          frameborder="0"
          src={convertToEmbedUrl(settingInfo?.video_homepage)}
          allow="autoplay; encrypted-media"
        >
        </iframe>
      </div>
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}>
        <div
          className="video-controls"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}>
            {/* <FaPlay
              onClick={() => {
                handlePlayVideo();
              }}
              style={{ color: "white" }}
              fontSize={"23px"}
            />
            <p style={{ color: "white", margin: " 10px 20px" }}>PLAY</p>
          </div>
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}> */}
            {/* <FaPause
              onClick={() => {
                handlePauseVideo();
              }}
              style={{ color: "white" }}
              fontSize={"23px"}
            />
            <p style={{ color: "white", margin: " 10px 20px" }}>
              PAUSE
            </p>
          </div>
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}> */}
            {/* {isMuted ? (
              <FaVolumeMute
                onClick={() => {
                  handleSoundVideo(false);
                  setIsMuted(false);
                }}
                style={{ color: "white" }}
                fontSize={"23px"}
              />
            ) : (
              <FaVolumeUp
                onClick={() => {
                  handleSoundVideo(true);
                  setIsMuted(true);
                }}
                style={{ color: "white" }}
                fontSize={"23px"}
              />
            )}
            <p style={{ color: "white", margin: " 10px 20px" }}>
              SOUND
            </p> */}
          </div>
        </div>
        <h3>
          <a href="https://youtu.be/xdn7sPuE-Sc" style={{color:'white'}} target="_blank">
            {/* KOSMIK LIVE CONCERT RECAP 2022 */}
          </a>
        </h3>
      </div>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '30px 0 70px 0'
      }}>
        {settingInfo?.list_video && JSON.parse(settingInfo?.list_video)?.map((ele, index) => {
          return (
            <div
              className='homepage-list-card-mobile'
              onClick={() => window.open(ele.linkYouTube)}
              style={{
                padding: "10px",
                border: "1px solid #2e2e2e",
                height: "250px",
                background: "#7d7d7d",
                cursor: "pointer",
              }}
            >
              {
                <div>
                  <img
                    width={"100%"}
                    style={{ maxHeight: "250px" }}
                    src={ele.image}
                    alt="#"
                  />
                  <p
                    className="homepage-card-name"
                    style={{
                      color: "white",
                      fontSize: "18px",
                      fontWeight: "bold",
                      margin: "10px 0",
                    }}
                  >
                    {ele.name}
                  </p>
                </div>
              }
            </div>
          );
        })}
      </div>
    </div>
  )
}

export default HomeMobile