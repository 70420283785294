import React, { useEffect } from "react";
import "./styles.scss";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import CONTENTS from "graphql/getContens.query";
import { useParams } from "react-router";
import { DATA } from "Components/Artists/ArtistsDetail/index";
import CCarousel from "Common/CCarousel";
import {xoa_dau} from 'Common/Util'

const DiscographyDetail = () => {
  const { state } = useLocation();
  const { id } = useParams();
//   console.log(id);
//   const detailData = state?.detailData;
//   const { loading, data } = useQuery(CONTENTS,     {
//     nextFetchPolicy:"network-only",
//     variables: {
//       where: {
//         projectId: "61726d65b5ad6b001dc7419b",
//         source: ["61726d65b5ad6b001dc741aa"],
// type: ["photo", "video", "link", "news", "mp3", "mp4", "pdf"]
//       }
//     }
//   });
//   const contentsData = data?.contentFilter;
//   const rs = contentsData?.find(x => xoa_dau(x.title.toString().replace(/ /g,"-").toLowerCase()) === xoa_dau(id) || x.id === id) || {};
//   const rsDetail = detailData ? detailData : rs;
//   const image = _.get(rsDetail, "images[0].src", "");
//   const desc = _.get(rsDetail, "description", "");
//   const listProduct = DATA.find(value => desc.includes(value.key)) || {};

//   useEffect(() => {
//     setTimeout(() => {
//       const element = document.querySelector("p:last-child .view-mv");
//       if (element && element.children.length === 1) {
//         var node = document.createElement("ion-icon");
//         node.setAttribute("name", "logo-youtube");
//         element.appendChild(node);
//       }
//     }, 500);
//   }, []);

  return (
      <div className="song-detail">
        <div className="song-image">

        </div>
        <div className="song-description">

        </div>
      </div>
    )
};

export default DiscographyDetail;
