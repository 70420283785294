import React, { useState, useEffect } from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import CONTENTS from "graphql/getContens.query";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { xoa_dau } from "Common/Util";
import recordData from "./Record/data.js";
import ListRecord from "./listRecord/index";
import axiosInstance from "api/axiosService";

function Discography(props) {
  let navigate = useNavigate();
  const _onDetail = (detailData) => {
    navigate(
      `detail/${detailData.id}`,
      { state: { detailData } }
    );
  };

  const [isHover, setIsHover] = useState(false);
  const [listArtist, setListArtist] = useState([]);
  // const dataChuck = _.chunk(contentsData, chuck);
  // console.log(dataChuck, "dataChuck");
  const { t } = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get('/api/artist/list?limit=1000');
      setListArtist(response.data.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
      // Xử lý lỗi tại đây
    }
  };
  return (
    <div className="discography-ssg">
      <div className="title tile-mb">{t("DISCOGRAPHY")}</div>
      {
        listArtist.map((ele) => {
          if (ele?.singles.length > 0) {
            return (
              <>
                <h1 style={{ color: 'white', marginBottom: '25px', fontWeight: 'bold' }}>{ele?.name.toUpperCase()}</h1>
                <div className="discography-all" style={{}}>
                  {ele?.singles.map((data, index) => {
                    return (
                      <ListRecord data={data} />
                    );
                  })}
                </div>
              </>
            );
          }
        })
      }
    </div>
  );
}

export default Discography;