// src/api/axiosService.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || 'https://spacespeakerwebsite-ec6t.vercel.app', // URL cơ sở của API
  headers: {
    'Content-Type': 'application/json',
    // Thêm các header khác nếu cần
  }
});

export default axiosInstance;
