
// import BINZ from "../../assets/images/ARTIST/Binz-real.jpg";
// import SOOBIN from "../../assets/images/ARTIST/SOOBIN-real.jpg";
// import RHYMASTIC from "../../assets/images/ARTIST/Rhymastic-real.jpg";
// import TOULIVER from "../../assets/images/ARTIST/Touliver-real.jpg";
// import SLIMV from "../../assets/images/ARTIST/Slimv-real.jpg";
// import TINLE from "../../assets/images/ARTIST/TINLE-real.jpg";
// import GONZO from "../../assets/images/ARTIST/GONZO-real.jpg";
// import TYPH from "../../assets/images/ARTIST/16 Typh-real.jpg";
// import MastaL from "../../assets/images/ARTIST/Mastal-real.jpg";

const artist = [
  {
    name: "BINZ",
    src: "/images/ARTISTS/DETAIL/Binz-real.jpg"
  },
  {
    name: "SOOBIN",
    src: "/images/ARTISTS/DETAIL/SOOBIN-real.jpg"
  },
  {
    name: "RHYMASTIC",
    src: "/images/ARTISTS/DETAIL/Rhymastic-real.jpg"
  },
  {
    name: "TOULIVER",
    src: "/images/ARTISTS/DETAIL/Touliver-real.jpeg"
  },
  {
    name: "SLIMV",
    src: "/images/ARTISTS/DETAIL/Slimv-real.jpeg"
  },
  {
    name: "TINLE",
    src: "/images/ARTISTS/DETAIL/TINLE-real.jpg"
  },
  {
    name: "GONZO",
    src: "/images/ARTISTS/DETAIL/GONZO-real.jpg"
  },
  {
    name: "16 TYPH",
    src: "/images/ARTISTS/DETAIL/16 Typh-real.jpg"
  },
  {
    name: "MastaL",
    src: "/images/ARTISTS/DETAIL/Mastal-real.jpeg"
  }
];

export { artist };
