import React, { useState, useEffect } from "react";
import "./../styles.scss";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import CONTENTS from "graphql/getContens.query";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Link } from "react-router-dom";

function ListRecord({ data }) {
  const [isHover, setIsHover] = useState(false);
  // const dataChuck = _.chunk(contentsData, chuck);
  // console.log(dataChuck, "dataChuck");
  const { t } = useTranslation();
  const redirectToYouTube = (url) => {
    const externalUrl = url;
    window.open(externalUrl, "_blank");
  };
  return (
    <div className="discography-all">
      <div className="content-container">
        <div
          key={data.id}
          className="box"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          // onClick={() => _onDetail(data)}
        >
          <div
            className="margin-box"
            onClick={() => redirectToYouTube(data.url)}
          >
            <img
              style={{ filter:isHover ? "blur(3px)" : "unset" }}
              className="song-display-image"
              alt="ssg"
              src={data.image}
            />
            <a
              style={{ display: isHover ? "block" : "none" }}
              className="to-ut-button"
              href={data.url}
              target="_blank"
            >
              Nghe Nhạc
            </a>
            <div className="title-song">{data.name}</div>
            <div className="des">{data.author}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListRecord;
