import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import _ from "lodash";
import CCarousel from "Common/CCarousel";
import CCarousel2 from "Common/CCarousel2";
import BINZ from "../../../assets/images/ARTIST/Binz-real.jpg";
import SOOBIN from "../../../assets/images/ARTIST/SOOBIN-real.jpg";
import RHYMASTIC from "../../../assets/images/ARTIST/Rhymastic-real.jpg";
import TOULIVER from "../../../assets/images/ARTIST/Touliver-real.jpeg";
import SLIMV from "../../../assets/images/ARTIST/Slimv-real.jpeg";
import TINLE from "../../../assets/images/ARTIST/TINLE-real.jpg";
import GONZO from "../../../assets/images/ARTIST/GONZO-real.jpg";
import TYPH from "../../../assets/images/ARTIST/16Typh-real.jpg";
import MastaL from "../../../assets/images/ARTIST/Mastal-real.jpeg";
import axios from '../../../api/axiosService';

function Detail(props) {
  let pName = useParams().name;
  // let data = DATA.find(value => value.name.toLocaleLowerCase() === pName);
  const [listVideo, setListVideo] = useState();

  const [data, setData] = useState({});
  const [video, setVideo] = useState({});
  // const { loading, data: dataQ } = useQuery(CONTENTS, {
  //   variables: {
  //     where: {
  //       projectId: "61726d65b5ad6b001dc7419b",
  //       source_id: data.id
  //     }
  //   }
  // });
  // const contentsData = dataQ?.contents || [];


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/artist/info?name=${pName}`);
      setData(response.data.data);
      setVideo({
        id: 1,
        link: response.data.data?.description_video,
      })
      setListVideo(response.data.singlesData);

      console.log('listVideo',listVideo)
    } catch (error) {
      console.error('Error fetching data: ', error);
      // Xử lý lỗi tại đây
    }
  };

  const { t ,i18n } = useTranslation();
  return (
    <div className="artists-detail">
      {_.isObject(data) && (
        <div className="content">
          <div className="info">
            <div className="image">
              <img src={data?.image} alt={data?.name} />
            </div>
            <div className="details">
              <div className="title">{data?.role}</div>
              <div className="name">{data?.name}</div>
              <div className="contact-icon">
                <a href={data?.youtube_link} target="_blank" rel="noreferrer">
                  <ion-icon name="logo-youtube"></ion-icon>
                </a>
                <a href={data?.facebook_link} target="_blank" rel="noreferrer">
                  <ion-icon name="logo-facebook"></ion-icon>
                </a>
                <a href={data?.instagram_link} target="_blank" rel="noreferrer">
                  <ion-icon name="logo-instagram"></ion-icon>
                </a>
              </div>
              <h2> <strong>About {data?.name}</strong> </h2>
              <p className="dob">D.O.B: {data?.dob}</p>
              <p className="brief-introduction">{i18n.language == 'vi' ? data?.description_VN : data?.description_ENG}</p>
              <h4 className="big-hits"> Big Hits: {data?.bigHits}</h4>
            </div>
          </div>
          <div className="production">
            <div className="title">{t("VỪA RA MẮT")}</div>
            <div className="video">
              <iframe
                width="100%"
                src={`https://www.youtube.com/embed/${getId(
                  _.get(video, "link", "")
                )}`}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>

            </div>
            <div className="list-thumbnail">
              {listVideo?.length > 0 && (
                <CCarousel>
                  {listVideo.map(value => (
                    <div key={value.id} className="thumbnail-item">
                      <img
                        onClick={() => setVideo(value)}
                        src={_.get(value, "image", "")}
                        alt={_.get(value, "image")}
                      />
                    </div>
                  ))}
                </CCarousel>
              )}
              {/* {!!contentsData.length && (
                <CCarousel>
                  {contentsData.map(value => (
                    <div key={value.id} className="thumbnail-item">
                      <img
                        onClick={() => setVideo(value)}
                        src={_.get(value, "images[0].src", "")}
                        alt={_.get(value, "title")}
                      />
                    </div>
                  ))}
                </CCarousel>
              )} */}

            </div>
          </div>
          {/* {data.discover && (
            <div className="discover">
              <div className="title">{t("DISCOVER MORE")}</div>
              <CCarousel2 items={data.discover} />
            </div>
          )} */}
        </div>
      )}
    </div>
  );
}

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
}

const DATA = [
  {
    id: "61ab39bdd4607e001d53bd3f",
    name: "BINZ",
    key: "Binz",
    position: "Rapper/Producer",
    DOB: "24/05",
    bigHits: "BigCityBoi, They Said, So far, OK, Krazy, Gene, Don’t Break My Heart",
    image: BINZ,
    facebook: "https://www.facebook.com/officialbinz/",
    instagram: "https://www.instagram.com/binzpoet/",
    youtube: "https://metub.net/binzdapoet",
    introduce: t => (
      <div className="introduce">
        <div>{t("binz-1")}</div>
        <div>{t("binz-2")}</div>
        {/* <div>{t("In 2020, Binz came back with a big hit")}</div> */}
      </div>
    ),
    debut: [
      {
        id: 1,
        link: "https://www.youtube.com/watch?v=jgZkrA8E5do&ab_channel=BinzDaPoet",
        image:
          "https://i.ytimg.com/vi/jgZkrA8E5do/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDZdVKrDz4thTBjXcD3TT4JmZSwVA"
      },
      {
        id: 2,
        link: "https://www.youtube.com/watch?v=2OKYsYErfFo&ab_channel=BinzDaPoet",
        image:
          "https://i.ytimg.com/vi/2OKYsYErfFo/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAkFzl_RMUOfhI0dCLb6rlknepnZw"
      },
      {
        id: 3,
        link: "https://www.youtube.com/watch?v=7UWOA9Afbxw&ab_channel=BinzDaPoet",
        image:
          "https://i.ytimg.com/vi/7UWOA9Afbxw/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDIXDAdavJ87mU21PtOYEUwwuDnUw"
      },
      {
        id: 4,
        link: "https://www.youtube.com/watch?v=_MM1MQgFjRM&ab_channel=BinzDaPoet",
        image:
          "https://i.ytimg.com/vi/_MM1MQgFjRM/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBUDyYDXmAi4OtrbI-Qf09dHB54hQ"
      },
      {
        id: 5,
        link: "https://www.youtube.com/watch?v=SNES5Y-tYxM&ab_channel=BinzDaPoet",
        image:
          "https://i.ytimg.com/vi/SNES5Y-tYxM/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDq5RlrHMMXjAx-nVVvVz-qCfAH3w"
      },
      {
        id: 6,
        link: "https://www.youtube.com/watch?v=cX-Oqdt7gmc&ab_channel=SpaceSpeakers",
        image:
          "https://i.ytimg.com/vi/cX-Oqdt7gmc/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAONZrZWnR5zKkHi2JhfKO52iC68Q"
      },
      {
        id: 7,
        link: "https://www.youtube.com/watch?v=yrPIlWfM75o&ab_channel=SpaceSpeakers",
        image:
          "https://i.ytimg.com/vi/yrPIlWfM75o/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBRFFDo7E8dpDEEl9jmdOqBL1FC5Q"
      },
      {
        id: 8,
        link: "https://www.youtube.com/watch?v=XdBsAXOxYfo&ab_channel=SpaceSpeakers",
        image:
          "https://i.ytimg.com/vi/XdBsAXOxYfo/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBzI_FoU6AvbEgovdtt9d8Rzv44WQ"
      },
      {
        id: 9,
        link: "https://www.youtube.com/watch?v=mlFiwKicz7I&ab_channel=SpaceSpeakers",
        image:
          "https://i.ytimg.com/vi/mlFiwKicz7I/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCNcaWtrHNlCxr_qablERW5BiL_6A"
      },
      {
        id: 10,
        link: "https://www.youtube.com/watch?v=z35r-OeqLgQ&ab_channel=BinzDaPoet",
        image:
          "https://i.ytimg.com/vi/z35r-OeqLgQ/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC6KIMuuph3DcnZTKmy3wJcZFRiXw"
      }
    ],

    discover: [
      {
        link: "/discography/detail/6187846dd2fc89001e69da09",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/e2f078a2036244e497c39ee901b055d8.jpg"
      },
      {
        link: "/discography/detail/618786c1bfd10d001ec929a6",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/204b50ba1d64422c801775b36399053e.jpg"
      },
      {
        link: "/discography/detail/61878661fbe6f1001e9bd2da",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/ee5e9f84e3a14aed861b9afd099c53bf.jpg"
      },
      {
        link: "/discography/detail/61878712fbe6f1001e9bd2e8",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/1a5aa8601e664475b97be24d4864d3c7.jpg"
      },
      {
        link: "/discography/detail/6187851ef62397001db361cf",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/d717483bf54f4a469e880db989d3edb0.jpg"
      },
      {
        link: "/discography/detail/618787897eee6f001ddc06c0",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/0488d351598d4d3da4cb1bab666673b5.jpg"
      }
    ]
  },
  {
    id: "61ab39fbbeaf3d001dd39daa",
    name: "RHYMASTIC",
    key: "Rhymastic",
    position: "Rapper/Singer/Composer/Producer",
    DOB: " 08/04",
    bigHits: "Yêu 5, Nến và Hoa, Vẽ Khói, Ngổn Ngang",
    image: RHYMASTIC,
    facebook: "https://www.facebook.com/Rhymastic.SS",
    instagram: "https://www.instagram.com/rhymastic/",
    youtube: "https://metub.net/rhymasticofficial",
    introduce: t => (
      <div className="introduce">
        <div>{t("rhymastic-1")}</div>
        <div>{t("rhymastic-2")}</div>

        {/* <div>{t("After Yeu 5 was released in 2017 and became a hit")}</div>
        <div>{t("Rhymastic released the single")}</div> */}
      </div>
    ),
    discover: [
      {
        link: "/discography/detail/6187819d8456fb001d0f1158",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/877eb1c2749246b692fb252e062d878a.jpg"
      },
      {
        link: "/discography/detail/618782927eee6f001ddc06a9",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/ebe471744bf14487a4623fe1e161bb5a.jpg"
      },
      {
        link: "/discography/detail/618781597eee6f001ddc06a2",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/87a31bd516f0484386ef56afd74f7e2e.jpg"
      },
      {
        link: "/discography/detail/618781ed8456fb001d0f1159",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/8717295e7c5f4fc191a98be088235d55.jpg"
      },
      {
        link: "/discography/detail/618782eefbe6f1001e9bd299",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/38c97fc5f9d144d98ccb18662a08d2e7.jpg"
      },
      {
        link: "/discography/detail/6187824ac9839f001eaf1d22",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/e41980c4de2b4bd99064ec0c982324ce.jpg"
      }
    ],
    debut: [
      {
        id: 1,
        link: "https://www.youtube.com/watch?v=XQkfHFA2aP0&ab_channel=RhymasticOfficial",
        image:
          "https://i.ytimg.com/vi/XQkfHFA2aP0/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB05umszXA3SHQMRJYLaYhaC3OOiQ"
      },
      {
        id: 2,
        link: "https://www.youtube.com/watch?v=fRAr0pj0gGE&ab_channel=RhymasticOfficial",
        image:
          "https://i.ytimg.com/vi/fRAr0pj0gGE/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDxPpnZ6DiYPUoR9nwgXE_h2JYZcQ"
      },
      {
        id: 3,
        link: "https://www.youtube.com/watch?v=5vBp9OPkIrE&ab_channel=RhymasticOfficial",
        image:
          "https://i.ytimg.com/vi/5vBp9OPkIrE/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDXQDMyFp0c_8mNddRsQ1q6gGibLg"
      },
      {
        id: 4,
        link: "https://www.youtube.com/watch?v=707bL_rFgyM&ab_channel=RhymasticOfficial",
        image:
          "https://i.ytimg.com/vi/707bL_rFgyM/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCA5XpCpzeGJeD-e50IEZtiohIdpQ"
      },
      {
        id: 5,
        link: "https://www.youtube.com/watch?v=xLYN94jkiDo&ab_channel=SpaceSpeakers",
        image:
          "https://i.ytimg.com/vi/xLYN94jkiDo/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDU-tlOGHyCkuMfYBRmL4dkhJ2hDQ"
      },
      {
        id: 6,
        link: "https://www.youtube.com/watch?v=SFZRBa16mcM&ab_channel=RhymasticOfficial",
        image:
          "https://i.ytimg.com/vi/SFZRBa16mcM/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDEv7yG6UlQuHKM3dXIhC1F855fWw"
      },
      {
        id: 7,
        link: "https://www.youtube.com/watch?v=zIrx2a6dckA&ab_channel=RhymasticOfficial",
        image:
          "https://i.ytimg.com/vi/zIrx2a6dckA/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAOEsYkmIKPbRxGagYLXjVaAZHutg"
      },
      {
        id: 8,
        link: "https://www.youtube.com/watch?v=C70YRVND4EM&ab_channel=RhymasticOfficial",
        image:
          "https://i.ytimg.com/vi/C70YRVND4EM/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCG2WdwvohM5lQxNryHy5e28O0j8Q"
      },
      {
        id: 9,
        link: "https://www.youtube.com/watch?v=WmDmTCvr_gw&ab_channel=RhymasticOfficial",
        image:
          "https://i.ytimg.com/vi/WmDmTCvr_gw/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCDiV-NfFcse-L_s1uDlWcoJpOHgw"
      },
      {
        id: 10,
        link: "https://www.youtube.com/watch?v=WnvUDdie1mc&ab_channel=Rhymastic-Topic",
        image:
          "https://i.ytimg.com/vi/WnvUDdie1mc/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCmWa0_XzPybkHi4MT95VfbpwFGAQ"
      }
    ]
  },
  {
    id: "61ab3edc10c153001ddf1045",
    name: "TOULIVER",
    key: "Touliver",
    position: "MUSIC PRODUCER",
    DOB: "21/01",
    bigHits: "BigCityboi, Có Ai Thương Em Như Anh, Vài Lần Đón Đưa, They Said",
    image: TOULIVER,
    facebook: "https://www.facebook.com/touliverss",
    instagram: "https://www.instagram.com/tlvrnsl/",
    youtube: "https://www.youtube.com/c/Touliver",
    introduce: t => (
      <div className="introduce">
        <div>{t("touliver-1")}</div>
        <div>{t("touliver-2")}</div>
        <div>{t("touliver-3")}</div>
        {/* <div>{t("Touliver is the first Vietnamese")}</div> */}
      </div>
    ),
    discover: [
      {
        link: "/discography/detail/61878383fbe6f1001e9bd2a0",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/3ebfb8fa125843fca65f4e9f303c2724.jpg"
      },
      {
        link: "/discography/detail/618783ced2fc89001e69da08",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/b4cc326cd05c4d2fb53d860413e22dd3.jpg"
      },
      {
        link: "/discography/detail/61878383fbe6f1001e9bd2a0",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/3ebfb8fa125843fca65f4e9f303c2724.jpg"
      },
      {
        link: "/discography/detail/618783ced2fc89001e69da08",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/b4cc326cd05c4d2fb53d860413e22dd3.jpg"
      },
      {
        link: "/discography/detail/61878383fbe6f1001e9bd2a0",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/3ebfb8fa125843fca65f4e9f303c2724.jpg"
      },
      {
        link: "/discography/detail/618783ced2fc89001e69da08",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/b4cc326cd05c4d2fb53d860413e22dd3.jpg"
      }
    ],
    debut: [
      {
        id: 1,
        link: "https://www.youtube.com/watch?v=N4a9Db9_ijc&ab_channel=SpaceSpeakers",
        image:
          "https://i.ytimg.com/vi/N4a9Db9_ijc/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAOBuNrIVVV61sBbiP9QYPW_Pnf6A"
      },
      {
        id: 2,
        link: "https://www.youtube.com/watch?v=jgZkrA8E5do&ab_channel=BinzDaPoet",
        image:
          "https://i.ytimg.com/vi/jgZkrA8E5do/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDZdVKrDz4thTBjXcD3TT4JmZSwVA"
      },
      {
        id: 3,
        link: "https://www.youtube.com/watch?v=Yw5MdbnBQ8s&ab_channel=SpaceSpeakers",
        image:
          "https://i.ytimg.com/vi/Yw5MdbnBQ8s/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBk9mk04fNaICx8bkPhTZhSxbGerw"
      },
      {
        id: 4,
        link: "https://www.youtube.com/watch?v=cX-Oqdt7gmc&ab_channel=SpaceSpeakers",
        image:
          "https://i.ytimg.com/vi/cX-Oqdt7gmc/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAONZrZWnR5zKkHi2JhfKO52iC68Q"
      },
      {
        id: 5,
        link: "https://www.youtube.com/watch?v=z5Jc7KiTLbs&ab_channel=SpaceSpeakers",
        image:
          "https://i.ytimg.com/vi/z5Jc7KiTLbs/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB3do6HUhnoeGRmTU_7U91qmxdeYw"
      },
      {
        id: 6,
        link: "https://www.youtube.com/watch?v=XdBsAXOxYfo&ab_channel=SpaceSpeakers",
        image:
          "https://i.ytimg.com/vi/XdBsAXOxYfo/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBzI_FoU6AvbEgovdtt9d8Rzv44WQ"
      },
      {
        id: 7,
        link: "https://www.youtube.com/watch?v=3CRuwDW5MEk&ab_channel=Touliver",
        image:
          "https://i.ytimg.com/vi/3CRuwDW5MEk/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAPSdF8p-Va7_rrSKVs8m0iwoZbWg"
      },
      {
        id: 8,
        link: "https://www.youtube.com/watch?v=neCmEbI2VWg&ab_channel=T%C3%B3cTi%C3%AAn",
        image:
          "https://i.ytimg.com/vi/neCmEbI2VWg/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAV25mhRGOu6FvuhyizIqY5fY5ysQ"
      },
      {
        id: 9,
        link: "https://www.youtube.com/watch?v=f3QmvxbzutQ&ab_channel=SpaceSpeakers",
        image:
          "https://i.ytimg.com/vi/f3QmvxbzutQ/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLASY_JIyHgsVrir7yfI2ue8jfXDZg"
      },
      {
        id: 10,
        link: "https://www.youtube.com/watch?v=GjSi4OxJORY&ab_channel=KimmeseOfficial",
        image:
          "https://i.ytimg.com/vi/GjSi4OxJORY/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAoDtvkemWNFLwZ826giN5LpiiSzg"
      }
    ]
  },
  {
    id: "61ab3a998bf08b001d03aef9",
    name: "SLIMV",
    key: "SlimV",
    position: "MUSIC PRODUCER",
    DOB: "21/01",
    bigHits: "The Playah, Remember Me, Em Ơi Cứ Vui",
    image: SLIMV,
    facebook: "https://www.facebook.com/SlimV.Producer.DJ",
    instagram: "https://www.instagram.com/caovinhslimv/",
    youtube: "https://metub.net/slimv",
    introduce: t => (
      <div className="introduce">
        <div>{t("slimv-1")}</div>
        <div>{t("slimv-2")}</div>
        {/* <div>{t("As a DJ, he had the chance to perform")}</div> */}
      </div>
    ),
    discover: [
      {
        link: "/discography/detail/618788cbf62397001db3620a",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/89db6b3cc2884b77bc886944c8c782df.jpg"
      },
      {
        link: "/discography/detail/61878884f8d5c8001efd6029",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/746efe256e5b41de9ac440ebc78ee1d4.jpg"
      },
      {
        link: "/discography/detail/6187883b7c841e001dd53307",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/db2e2b218ee14974bae735f8db83c5b1.jpg"
      },
      {
        link: "/discography/detail/618788cbf62397001db3620a",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/89db6b3cc2884b77bc886944c8c782df.jpg"
      },
      {
        link: "/discography/detail/61878884f8d5c8001efd6029",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/746efe256e5b41de9ac440ebc78ee1d4.jpg"
      },
      {
        link: "/discography/detail/6187883b7c841e001dd53307",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/db2e2b218ee14974bae735f8db83c5b1.jpg"
      }
    ],
    debut: [
      {
        id: 1,
        link: "https://www.youtube.com/watch?v=d44UTUSTYKU&ab_channel=SOOBINOfficial",
        image:
          "https://i.ytimg.com/vi/d44UTUSTYKU/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDRg0opjqZYtRUNSd2H8pTYM8--5g"
      },
      {
        id: 2,
        link: "https://www.youtube.com/watch?v=zIKhCyRRZ5c&ab_channel=SlimV",
        image:
          "https://i.ytimg.com/vi/zIKhCyRRZ5c/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB45sMBcpLEpfNft7EsyoAXKORBWg"
      },
      {
        id: 3,
        link: "https://www.youtube.com/watch?v=jzYJDsgNKKc&ab_channel=BinzDaPoet",
        image:
          "https://i.ytimg.com/vi/jzYJDsgNKKc/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAmNZwV4WLuDMOtqfHU0uZbqr5ENQ"
      },
      {
        id: 4,
        link: "https://www.youtube.com/watch?v=dOZ1gK5YFBI&ab_channel=SOOBINOfficial",
        image:
          "https://i.ytimg.com/vi/dOZ1gK5YFBI/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB-pxTGZabKIlPfi6fnoVlHzBdqpQ"
      },
      {
        id: 5,
        link: "https://www.youtube.com/watch?v=rLvmeh8mOBc&ab_channel=SlimV",
        image:
          "https://i.ytimg.com/vi/rLvmeh8mOBc/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCM2BM-WlV4vM68mnbsXSpZXGr5cQ"
      },
      {
        id: 6,
        link: "https://www.youtube.com/watch?v=mbo-jeSazro&ab_channel=SlimV",
        image:
          "https://i.ytimg.com/vi/mbo-jeSazro/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCbuowI_85YypkXqsAldzrjc6w0MQ"
      },
      {
        id: 7,
        link: "https://www.youtube.com/watch?v=O0kqkRvrO88&ab_channel=SlimV",
        image:
          "https://i.ytimg.com/vi/O0kqkRvrO88/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDNWKUEao1dS1I1hlcRcKn8ilO3gg"
      },
      {
        id: 8,
        link: "https://www.youtube.com/watch?v=xNptp_fhoe4&ab_channel=SlimV",
        image:
          "https://i.ytimg.com/vi/xNptp_fhoe4/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA0geWO35s74cqmFkOXLFpH55f_dA"
      },
      {
        id: 9,
        link: "https://www.youtube.com/watch?v=6piLWk3FaAc&ab_channel=SlimV",
        image:
          "https://i.ytimg.com/vi/6piLWk3FaAc/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBatcOULKoveQz5tM9mMFEzKUHzog"
      },
      {
        id: 10,
        link: "https://www.youtube.com/watch?v=6Edc6xxV93M&ab_channel=SlimV",
        image:
          "https://i.ytimg.com/vi/6Edc6xxV93M/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBtSH1XmtrshaUGl0emRdB1obIACQ"
      }
    ]
  },
  {
    id: "61776a961a6a2b001eea8056",
    name: "SOOBIN",
    key: "Soobin",
    position: "Singer/Composer/Producer",
    DOB: "10/09",
    bigHits: "Phía Sau Một Cô Gái, La La La, The Playah, BlackJack, Tháng Năm",
    image: SOOBIN,
    facebook: "https://www.facebook.com/soobin.109",
    instagram: "https://www.instagram.com/soobin.hoangson/",
    youtube: "https://metub.net/soobinhoangson",
    introduce: t => (
      <div className="introduce">
        <div>{t("soobin-1")}</div>
        <div>{t("soobin-2")}</div>
        {/* <div>{t("Năm 2020, SOOBIN quyết định trở lại")}</div>
        <div>{t("SOOBIN kết hợp cùng SlimV")}</div> */}
      </div>
    ),
    discover: [
      {
        link: "/discography/detail/61877e7fc9839f001eaf1d05",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/596f100675574de4a1f7523ed920386b.png"
      },
      {
        link: "/discography/detail/61877d9fc9839f001eaf1cfd",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/2a7d385e250746959a09a6484dee2bde.png"
      },
      {
        link: "/discography/detail/61877f0f7c841e001dd532c3",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/9ca7dd335b6e4bc98518f01186ac60da.png"
      },
      {
        link: "/discography/detail/6182a2c246932c001d9db97b",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/63343c9418234629b2c0951292e1f877.png"
      },
      {
        link: "/discography/detail/61877f7fc9839f001eaf1d0c",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/9baf122eea8848d7b0a47d1536bc7621.png"
      },
      {
        link: "/discography/detail/61877fd6c9839f001eaf1d1b",
        img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/b41faa6340c5456d935db375e02cd08a.png"
      }
    ],
    debut: [
      {
        id: 1,
        link: "https://www.youtube.com/watch?v=d44UTUSTYKU&ab_channel=SOOBINOfficial",
        image:
          "https://i.ytimg.com/vi/d44UTUSTYKU/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDRg0opjqZYtRUNSd2H8pTYM8--5g"
      },
      {
        id: 2,
        link: "https://www.youtube.com/watch?v=zRdHnseM0PE&ab_channel=SOOBINOfficial",
        image:
          "https://i.ytimg.com/vi/zRdHnseM0PE/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDZd-u1o9_vjK5WvAwt6vEUO-h6jw"
      },
      {
        id: 3,
        link: "https://www.youtube.com/watch?v=QFHvfQDgRFA&ab_channel=SOOBINOfficial",
        image:
          "https://i.ytimg.com/vi/QFHvfQDgRFA/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCwxUn7-piN36IfFYYm9VJKr1Y37A"
      },
      {
        id: 4,
        link: "https://www.youtube.com/watch?v=sG9JhIRuTkA&ab_channel=SOOBINOfficial",
        image:
          "https://i.ytimg.com/vi/sG9JhIRuTkA/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCnJKPjz8ir69uBjnio-QD8j5JnTg"
      },
      {
        id: 5,
        link: "https://www.youtube.com/watch?v=J06MCOrd2jI&ab_channel=SOOBINOfficial",
        image:
          "https://i.ytimg.com/vi/J06MCOrd2jI/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDVqsTIamijMkG3E-vqS-a8JoJbfg"
      },
      {
        id: 6,
        link: "https://www.youtube.com/watch?v=7kP8Qnu2TJ8&ab_channel=SpaceSpeakers",
        image:
          "https://i.ytimg.com/vi/7kP8Qnu2TJ8/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDU7xqlYgnS2LiE5n91guYP8aT5cw"
      },
      {
        id: 7,
        link: "https://www.youtube.com/watch?v=z5Jc7KiTLbs&ab_channel=SpaceSpeakers",
        image:
          "https://i.ytimg.com/vi/z5Jc7KiTLbs/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB3do6HUhnoeGRmTU_7U91qmxdeYw"
      },
      {
        id: 8,
        link: "https://www.youtube.com/watch?v=f3QmvxbzutQ&ab_channel=SpaceSpeakers",
        image:
          "https://i.ytimg.com/vi/f3QmvxbzutQ/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLASY_JIyHgsVrir7yfI2ue8jfXDZg"
      },
      {
        id: 9,
        link: "https://www.youtube.com/watch?v=X7sSE3yCNLI&ab_channel=SOOBINOfficial",
        image:
          "https://i.ytimg.com/vi/X7sSE3yCNLI/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDARYpA9u8IJxtzxwIur_SfnqxmtQ"
      },
      {
        id: 10,
        link: "https://www.youtube.com/watch?v=vCIc1g_4JWM&ab_channel=SOOBINOfficial",
        image:
          "https://i.ytimg.com/vi/vCIc1g_4JWM/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCp8vGP0YaW-SHtOsfjhXlMJejLpw"
      }
    ]
  },
  {
    id: "1",
    name: "TINLE",
    key: "TinLe",
    position: "MUSIC PRODUCER",
    DOB: "10/10",
    bigHits: "Nước Mắt Em Lau Bằng Tình Yêu Mới, Missing You, Nhạc Anh",
    image: TINLE,
    facebook: "https://www.facebook.com/tinleproducer",
    instagram: "https://www.instagram.com/tinle.ss/",
    youtube: "https://www.youtube.com/channel/UC2BVdbkemMigPqV1m-VqIYA/",
    introduce: t => (
      <div className="introduce">
        <div>
          {t("tinle-1")}
        </div>
        <div>
          {t("tinle-2")}
        </div>
        {/* <div>
          {t(
            "TINLE từng hợp tác cùng Tóc Tiên, Lưu Hương Giang, Ali Hoàng Dương, Lưu Thiên Hương, Hồ Hoài Anh... và có được nhiều sản phẩm gây tiếng vang như: Ngày Mai Em Đi, Anh Đã Quen Với Cô Đơn, I Know You Know (SOOBIN), Thôi Anh Không Chơi, Sofar remix (Binz), Stardom (Vũ Cát Tường)..."
          )}
        </div> */}
      </div>
    ),
    debut: [
      {
        id: 1,
        link: "https://www.youtube.com/watch?v=PR_yVho1Txc&ab_channel=PhuongLyOfficial",
        image:
          "https://i.ytimg.com/vi/PR_yVho1Txc/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAxJP5GTcQugRK9ESomkevwFdFqBw"
      },
      {
        id: 2,
        link: "https://www.youtube.com/watch?v=CgB67LN6uOI&ab_channel=TINLEOfficial",
        image:
          "https://i.ytimg.com/vi/CgB67LN6uOI/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCDkVacdhKFwH7V1C_8VJtLkN_fiQ"
      },
      {
        id: 2.2,
        link: "https://www.youtube.com/watch?v=2RlTwQ1m464&ab_channel=TINLEOfficial",
        image:
          "https://i.ytimg.com/vi/2RlTwQ1m464/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLANjm79-YJ1V46skkx_SKCLzkKahg"
      },
      {
        id: 3,
        link: "https://www.youtube.com/watch?v=nCJWHDNTHE8&ab_channel=TINLEOfficial",
        image:
          "https://i.ytimg.com/vi/nCJWHDNTHE8/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDqORyMOXX73jJ5h4SQAVmdbC56mA"
      },
      {
        id: 3.1,
        link: "https://www.youtube.com/watch?v=leX9S__HRGg&ab_channel=TINLEOfficial",
        image:
          "https://i.ytimg.com/vi/leX9S__HRGg/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAeg_Cm4TsWO_k43yLIX2KhjEnBDg"
      },
      {
        id: 4,
        link: "https://www.youtube.com/watch?v=570JGtfgN0k&ab_channel=TINLEOfficial",
        image:
          "https://i.ytimg.com/vi/570JGtfgN0k/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCRD68WAUFY3AbXUTHvgEE5N9Q2Qg"
      },
      {
        id: 5,
        link: "https://www.youtube.com/watch?v=-Odib99iVOg&ab_channel=TINLEOfficial",
        image:
          "https://i.ytimg.com/vi/-Odib99iVOg/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCcm60MP0BsyxvYAzu2MVQQ7_O7Zw"
      },
      {
        id: 6,
        link: "https://www.youtube.com/watch?v=6ZR0WTpS354&ab_channel=TINLEOfficial",
        image:
          "https://i.ytimg.com/vi/6ZR0WTpS354/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBuzEnjguU2PccNQdFYq0fbZcnHUg"
      },
      {
        id: 7,
        link: "https://www.youtube.com/watch?v=6dfTgIWtr7s&ab_channel=TINLEOfficial",
        image:
          "https://i.ytimg.com/vi/6dfTgIWtr7s/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBwI-XiZh_-ahloUfveGf0vF0AFcA"
      },
      {
        id: 8,
        link: "https://www.youtube.com/watch?v=iUHFhYMrtzs&ab_channel=16Typh",
        image:
          "https://i.ytimg.com/vi/iUHFhYMrtzs/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA0nGiZLjKaMh6Ij95XaoM-kF6SwQ"
      }
    ]

    // discover: [
    //   {
    //     link: "/discography/detail/61877e7fc9839f001eaf1d05",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/596f100675574de4a1f7523ed920386b.png"
    //   },
    //   {
    //     link: "/discography/detail/61877d9fc9839f001eaf1cfd",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/2a7d385e250746959a09a6484dee2bde.png"
    //   },
    //   {
    //     link: "/discography/detail/61877f0f7c841e001dd532c3",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/9ca7dd335b6e4bc98518f01186ac60da.png"
    //   },
    //   {
    //     link: "/discography/detail/6182a2c246932c001d9db97b",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/63343c9418234629b2c0951292e1f877.png"
    //   },
    //   {
    //     link: "/discography/detail/61877f7fc9839f001eaf1d0c",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/9baf122eea8848d7b0a47d1536bc7621.png"
    //   },
    //   {
    //     link: "/discography/detail/61877fd6c9839f001eaf1d1b",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/b41faa6340c5456d935db375e02cd08a.png"
    //   }
    // ]
  },
  {
    id: "2",
    name: "16 Typh",
    key: "16 typh",
    position: "Rapper",
    DOB: "02/02",
    bigHits: "Don’t Waste My Time, Walk on da street, Million Dollar Boy",
    image: TYPH,
    facebook: "https://www.facebook.com/typh16ns",
    instagram: "https://www.instagram.com/typh.16/?hl=en",
    youtube: "https://www.youtube.com/channel/UCG44ngVzqC1Jp_WBWOvNx1g",
    introduce: t => (
      <div className="introduce">
        <div>
          <p>{t("16typh-1")}</p>
          <p>{t("16typh-2")}</p>
          <p>{t("16typh-3")}</p>
          <p>{t("16typh-4")}</p>
        </div>
        {/* <div>
          {t(
            "TINLE từng hợp tác cùng Tóc Tiên, Lưu Hương Giang, Ali Hoàng Dương, Lưu Thiên Hương, Hồ Hoài Anh... và có được nhiều sản phẩm gây tiếng vang như: Ngày Mai Em Đi, Anh Đã Quen Với Cô Đơn, I Know You Know (SOOBIN), Thôi Anh Không Chơi, Sofar remix (Binz), Stardom (Vũ Cát Tường)..."
          )}
        </div> */}
      </div>
    ),
    debut: [
      {
        id: 1,
        link: "https://www.youtube.com/watch?v=y-Tut29zvPE",
        image:
          "https://i.ytimg.com/vi/y-Tut29zvPE/maxresdefault.jpg"
      },
      {
        id: 2,
        link: "https://www.youtube.com/watch?v=pLYJNKD7M-Y",
        image:
          "https://i.ytimg.com/vi/pLYJNKD7M-Y/maxresdefault.jpg"
      },
      {
        id: 2.2,
        link: "https://www.youtube.com/watch?v=fLsbkrT0LYY",
        image:
          "https://i.ytimg.com/vi/fLsbkrT0LYY/maxresdefault.jpg"
      },
    ]

    // discover: [
    //   {
    //     link: "/discography/detail/61877e7fc9839f001eaf1d05",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/596f100675574de4a1f7523ed920386b.png"
    //   },
    //   {
    //     link: "/discography/detail/61877d9fc9839f001eaf1cfd",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/2a7d385e250746959a09a6484dee2bde.png"
    //   },
    //   {
    //     link: "/discography/detail/61877f0f7c841e001dd532c3",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/9ca7dd335b6e4bc98518f01186ac60da.png"
    //   },
    //   {
    //     link: "/discography/detail/6182a2c246932c001d9db97b",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/63343c9418234629b2c0951292e1f877.png"
    //   },
    //   {
    //     link: "/discography/detail/61877f7fc9839f001eaf1d0c",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/9baf122eea8848d7b0a47d1536bc7621.png"
    //   },
    //   {
    //     link: "/discography/detail/61877fd6c9839f001eaf1d1b",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/b41faa6340c5456d935db375e02cd08a.png"
    //   }
    // ]
  },
  {
    id: "2222",
    name: "MastaL",
    key: "MastaL",
    position: "MUSIC PRODUCER",
    DOB: "05/05",
    bigHits: "Đời Thật Rap Thật, Ez Papa, Dân chơi xóm",
    image: MastaL,
    facebook: "https://www.facebook.com/mastal95",
    instagram: "https://www.instagram.com/typh.16/?hl=en",
    youtube: "https://www.youtube.com/@Mast4l",
    introduce: t => (
      <div className="introduce">
        <div>
          {t("mastal-1")}
        </div>
        <div>
          {t("mastal-2")}
        </div>
        {/* <div>
          {t(
            "TINLE từng hợp tác cùng Tóc Tiên, Lưu Hương Giang, Ali Hoàng Dương, Lưu Thiên Hương, Hồ Hoài Anh... và có được nhiều sản phẩm gây tiếng vang như: Ngày Mai Em Đi, Anh Đã Quen Với Cô Đơn, I Know You Know (SOOBIN), Thôi Anh Không Chơi, Sofar remix (Binz), Stardom (Vũ Cát Tường)..."
          )}
        </div> */}
      </div>
    ),
    debut: [
      {
        id: 1,
        link: "https://www.youtube.com/watch?v=R7-WTX9GRBU",
        image:
          "https://i.ytimg.com/vi/R7-WTX9GRBU/sddefault.jpg?v=6476f213"
      },
      {
        id: 2,
        link: "https://www.youtube.com/watch?v=rxBsiVhK2Aw",
        image:
          "https://i.ytimg.com/vi/rxBsiVhK2Aw/mqdefault.jpg"
      },
      {
        id: 2.2,
        link: "https://www.youtube.com/watch?v=Wn1ucSLmRO4",
        image:
          "https://i.ytimg.com/vi/Svah32zAPIw/maxresdefault.jpg"
      },
    ]

    // discover: [
    //   {
    //     link: "/discography/detail/61877e7fc9839f001eaf1d05",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/596f100675574de4a1f7523ed920386b.png"
    //   },
    //   {
    //     link: "/discography/detail/61877d9fc9839f001eaf1cfd",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/2a7d385e250746959a09a6484dee2bde.png"
    //   },
    //   {
    //     link: "/discography/detail/61877f0f7c841e001dd532c3",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/9ca7dd335b6e4bc98518f01186ac60da.png"
    //   },
    //   {
    //     link: "/discography/detail/6182a2c246932c001d9db97b",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/63343c9418234629b2c0951292e1f877.png"
    //   },
    //   {
    //     link: "/discography/detail/61877f7fc9839f001eaf1d0c",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/9baf122eea8848d7b0a47d1536bc7621.png"
    //   },
    //   {
    //     link: "/discography/detail/61877fd6c9839f001eaf1d1b",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/b41faa6340c5456d935db375e02cd08a.png"
    //   }
    // ]
  },
  {
    id: "00000",
    name: "Gonzo",
    key: "Gonzo",
    position: "Rapper",
    DOB: "11/07",
    bigHits: "Thầy Nam, Dear",
    image: GONZO,
    facebook: "https://www.facebook.com/MuppetsGonzo/",
    instagram: "https://www.instagram.com/rpt.gonzo/?hl=en",
    youtube: "https://www.youtube.com/@gonzossl",
    introduce: t => (
      <div className="introduce">
        <div>
          {t("gonzo-1")}
        </div>
        <div>
          {t("gonzo-2")}
        </div>
        {/* <div>
          {t(
            "TINLE từng hợp tác cùng Tóc Tiên, Lưu Hương Giang, Ali Hoàng Dương, Lưu Thiên Hương, Hồ Hoài Anh... và có được nhiều sản phẩm gây tiếng vang như: Ngày Mai Em Đi, Anh Đã Quen Với Cô Đơn, I Know You Know (SOOBIN), Thôi Anh Không Chơi, Sofar remix (Binz), Stardom (Vũ Cát Tường)..."
          )}
        </div> */}
      </div>
    ),
    debut: [
      {
        id: 1,
        link: "https://www.youtube.com/watch?v=1VxQqEUsgtU",
        image:
          "https://i.ytimg.com/vi/DPPp4NIm76c/maxresdefault.jpg"
      },
      {
        id: 2,
        link: "https://www.youtube.com/watch?v=kSRTQv1qEno",
        image:
          "https://i.ytimg.com/vi/kSRTQv1qEno/maxresdefault.jpg"
      },
    ]

    // discover: [
    //   {
    //     link: "/discography/detail/61877e7fc9839f001eaf1d05",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/596f100675574de4a1f7523ed920386b.png"
    //   },
    //   {
    //     link: "/discography/detail/61877d9fc9839f001eaf1cfd",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/2a7d385e250746959a09a6484dee2bde.png"
    //   },
    //   {
    //     link: "/discography/detail/61877f0f7c841e001dd532c3",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/9ca7dd335b6e4bc98518f01186ac60da.png"
    //   },
    //   {
    //     link: "/discography/detail/6182a2c246932c001d9db97b",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/63343c9418234629b2c0951292e1f877.png"
    //   },
    //   {
    //     link: "/discography/detail/61877f7fc9839f001eaf1d0c",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/9baf122eea8848d7b0a47d1536bc7621.png"
    //   },
    //   {
    //     link: "/discography/detail/61877fd6c9839f001eaf1d1b",
    //     img: "https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/b41faa6340c5456d935db375e02cd08a.png"
    //   }
    // ]
  }
];

export { DATA };

export default Detail;
