import React, { useState, useEffect } from "react";
import { artist } from "./data";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import axiosInstance from "api/axiosService";

const ArtistList = props => {
  let navigate = useNavigate();
  const [listArtist, setListArtist] = useState([]);
  const [chuck, setChuck] = useState(
    window.innerWidth < 725 ? 2 : artist.length
  );
  const fetchListArtist = async () => {
    try {
      const response = await axiosInstance.get('/api/artist/list?limit=1000');
      setListArtist(response.data?.data)
      // setSettingInfo(response.data.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
      // Xử lý lỗi tại đây
    }
  };
  useEffect(() => {
    fetchListArtist();
    function handleResize() {
      if (window.innerWidth < 725) {
        setChuck(2);
      } else {
        setChuck(artist.length);
      }
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const _onDetail = name => {
    navigate(`/artists/${name}`);
  };

  return (
    <div className="artist">
          <div className="chuck-list">
            {listArtist?.map((items, index) => (
              <div
                className="box-art"
                key={index}
                onClick={() =>
                  props.data ? null : _onDetail(items.name)
                }
              >
                <img className="img-art" alt="art" src={items?.image} loading="lazy"/>
                <div className="footer">
                  <div>{items.name}</div>
                </div>
              </div>
            ))}
          </div>
    </div>
  );
};

export default ArtistList;
