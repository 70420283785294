import image1 from "../../assets/images/homepage/maxresdefault1.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717555916/uploads/maxresdefault1.jpg
import image2 from "../../assets/images/homepage/maxresdefault2.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717555958/uploads/maxresdefault2.jpg
import image3 from "../../assets/images/homepage/maxresdefault3.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556012/uploads/maxresdefault3.jpg
import image4 from "../../assets/images/homepage/maxresdefault4.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556025/uploads/maxresdefault4.jpg
import image5 from "../../assets/images/homepage/maxresdefault5.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556042/uploads/maxresdefault5.jpg
import image6 from "../../assets/images/homepage/maxresdefault6.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556058/uploads/maxresdefault6.jpg
import image7 from "../../assets/images/homepage/maxresdefault7.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556074/uploads/maxresdefault7.jpg
import image8 from "../../assets/images/homepage/maxresdefault8.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556084/uploads/maxresdefault8.jpg
import image9 from "../../assets/images/homepage/maxresdefault9.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556097/uploads/maxresdefault9.jpg
import image10 from "../../assets/images/homepage/maxresdefault10.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556112/uploads/maxresdefault10.jpg
import image11 from "../../assets/images/homepage/maxresdefault11.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556122/uploads/maxresdefault11.jpg
import image12 from "../../assets/images/homepage/maxresdefault12.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556141/uploads/maxresdefault12.jpg
import image13 from "../../assets/images/homepage/maxresdefault13.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556153/uploads/maxresdefault13.jpg
import image14 from "../../assets/images/homepage/maxresdefault14.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556166/uploads/maxresdefault14.jpg
import image15 from "../../assets/images/homepage/maxresdefault15.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556179/uploads/maxresdefault15.jpg
import image16 from "../../assets/images/homepage/maxresdefault16.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556191/uploads/maxresdefault16.jpg
import image17 from "../../assets/images/homepage/maxresdefault17.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556200/uploads/maxresdefault17.jpg
import image18 from "../../assets/images/homepage/maxresdefault18.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556211/uploads/maxresdefault18.jpg
import image19 from "../../assets/images/homepage/maxresdefault19.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556221/uploads/maxresdefault19.jpg
import image20 from "../../assets/images/homepage/maxresdefault20.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556231/uploads/maxresdefault20.jpg
import image21 from "../../assets/images/homepage/maxresdefault21.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556245/uploads/maxresdefault21.jpg
import image22 from "../../assets/images/homepage/maxresdefault22.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556259/uploads/maxresdefault22.jpg
import image23 from "../../assets/images/homepage/maxresdefault23.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556270/uploads/maxresdefault23.jpg
import image24 from "../../assets/images/homepage/maxresdefault24.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556284/uploads/maxresdefault24.jpg
import image25 from "../../assets/images/homepage/maxresdefault25.jpg"; // https://res.cloudinary.com/daskwafdo/image/upload/v1717556307/uploads/maxresdefault25.jpg

export const listIsHover = [
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
];
export const listIsHoverTrue = [
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
];
export const videoArray = [
  {
    img: image1,
    name: 'TOULIVER x BINZ - "BIGCITYBOI" (Official Music Video)',
    linkYouTube: "https://youtu.be/jgZkrA8E5do",
    time: "07.05.2020",
  },
  {
    img: image2,
    name: "The Karman Line - Hợp tác VNA x SSG | Documentary",
    linkYouTube: "https://youtu.be/U3U27DwB1-M",
    time: "01.13.2022",
  },
  {
    img: image3,
    name: "SPACESPEAKERS - A VEIL OF MIST (Official MV)",
    linkYouTube: "https://youtu.be/8HQ8ksO684s",
    time: "10.31.2022",
  },
  {
    img: image4,
    name: "SOOBIN X SLIMV - THE PLAYAH (Special Performance / Official Music Video)",
    linkYouTube: "https://youtu.be/d44UTUSTYKU",
    time: "05.14.2021",
  },
  {
    img: image5,
    name: "LUẬT RỪNG - OFFICIAL MV - BINZ x RHYMASTIC x 16 TYPH x GONZO x TINLE",
    linkYouTube: "https://youtu.be/1eOJyjXhGmw",
    time: "08.04.2022",
  },
  {
    img: image6,
    name: `BINZ x TOULIVER - DON'T BREAK MY HEART (OFFICIAL MV)`,
    linkYouTube: "https://youtu.be/Rrr-5-4wcEs",
    time: "03.14.2022",
  },
  {
    img: image7,
    name: `Rhymastic - Nến Và Hoa (Official Audio)`,
    linkYouTube: "https://youtu.be/D164TFHeOcI",
    time: "08.18.2018",
  },
  {
    img: image8,
    name: `SPACESPEAKERS - NHANH LÊN NHÉ! (OFFICIAL MUSIC VIDEO)`,
    linkYouTube: "https://youtu.be/uQHHzgROvjw",
    time: "07.11.2022",
  },
  {
    img: image9,
    name: `SOOBIN & BINZ (DOUBLE B) - BlackJack ft. GOKU (Official Music Video)`,
    linkYouTube: "https://youtu.be/zRdHnseM0PE",
    time: "11.30.2020",
  },
  {
    img: image10,
    name: `16 Typh - MILLION DOLLAR BOY (Official MV)`,
    linkYouTube: "https://youtu.be/fLsbkrT0LYY",
    time: "08.15.2022",
  },
  {
    img: image11,
    name: `TOULIVER X BINZ - GENE [ OFFICIAL MV ]`,
    linkYouTube: "https://youtu.be/cX-Oqdt7gmc",
    time: "05.12.2019",
  },
  {
    img: image12,
    name: `LẠC - Rhymastic (Lyrics Video)`,
    linkYouTube: "https://youtu.be/5vBp9OPkIrE",
    time: "10.22.2021",
  },
  {
    img: image13,
    name: `KOSMIK LIVE CONCERT RECAP`,
    linkYouTube: "https://youtu.be/xdn7sPuE-Sc",
    time: "12.06.2022",
  },
  {
    img: image14,
    name: `SOOBIN - THÁNG NĂM (Official Music Video)`,
    linkYouTube: "https://youtu.be/sG9JhIRuTkA",
    time: "12.15.2020",
  },
  {
    img: image15,
    name: `SOOBIN, Binz - BlackJack | KOSMIK Live Concert`,
    linkYouTube: "https://youtu.be/xdLr7BG2GLg",
    time: "12.09.2022",
  },
  {
    img: image16,
    name: `BINZ - OK (Official Music Video)`,
    linkYouTube: "https://youtu.be/SNES5Y-tYxM",
    time: "01.01.2020",
  },
  {
    img: image17,
    name: `TRAPNALOG - Analog Session #5`,
    linkYouTube: "https://youtu.be/0kabl4_soAE",
    time: "02.14.2021",
  },
  {
    img: image18,
    name: `SPACESPEAKERS - FREAKY SQUAD (OFFICIAL MUSIC VIDEO)`,
    linkYouTube: "https://youtu.be/N4a9Db9_ijc",
    time: "11.30.2021",
  },
  {
    img: image19,
    name: `SOOBIN - TRÒ CHƠI (Official Music Video)`,
    linkYouTube: "https://youtu.be/QFHvfQDgRFA",
    time: "11.21.2020",
  },
  {
    img: image20,
    name: `BINZ - DON’T BREAK MY HEART (TINLE REMIX)`,
    linkYouTube: "https://youtu.be/vFf9jphDBYU",
    time: "04.25.2022",
  },
  {
    img: image21,
    name: `TOULIVER X BINZ - THEY SAID [ OFFICIAL MV ]`,
    linkYouTube: "https://youtu.be/XdBsAXOxYfo",
    time: "12.02.2017",
  },
  {
    img: image22,
    name: `Touliver - Kính Vạn Hoa (Feat. Tiên Tiên) [Official MV]`,
    linkYouTube: "https://youtu.be/gBqEHAg_yto",
    time: "01.04.2022",
  },
  {
    img: image23,
    name: `SPACESPEAKERS - EVERYDAY [ OFFICIAL MV ]`,
    linkYouTube: "https://youtu.be/uwlGzAprCrw",
    time: "05.08.2018",
  },
  {
    img: image24,
    name: `SOOBIN - CAO GÓT | OFFICIAL MUSIC VIDEO`,
    linkYouTube: "https://youtu.be/AiKmVpT_I3o",
    time: "11.14.2022",
  },
  {
    img: image25,
    name: `SOOBIN X BINZ - BEAUTIFUL MONSTER | Official MV`,
    linkYouTube: "https://youtu.be/AiKmVpT_I3o",
    time: "11.03.2022",
  },
];

export default {
  listIsHover: listIsHover,
  listIsHoverTrue: listIsHoverTrue,
  videoArray: videoArray
}