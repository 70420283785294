import CCarousel2 from "Common/CCarousel2";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

function SPACEJAM(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="space-jam">
      <main>
        <img alt="ssg" src=" /images/PROJECT/space-jam.jpg" />
        <div className="topic">
          <desc>{t("DỰ ÁN")}</desc>
          <div className="title">{t("SPACE JAM #1")}</div>
          <div className="content">
            <div>{t("Nằm trong hệ sinh thái")}</div>
            <br />
            <div>{t("Vỏn vẹn 2 ngày nhưng Space Jam đã mang lại")}</div>
            <br />
            <div>{t("Ngay từ bước lên ý tưởng")}</div>
            <br />
            <div>{t("Với thiết kế mô hình trại sáng tác")}</div>
            <br />
            <div>{t("Là tổ chức âm nhạc tiên phong mang")}</div>
            <br />
            <div>{t("Space Jam")}</div>
            <div>{t("Trại sáng tác âm nhạc đầu")}</div>
            <div>{t("Thời gian âm nhạc của")}</div>
            <div>{t("Có 20 nghệ sĩ tham gia sự kiện")}</div>
            <div>{t("Đà Lạt là điểm dừng chân")}</div>
            <div>{t("Có 5 ca khúc chỉ biểu diễn")}</div>
            <div>{t("Space Jam là sự kiện hiếm hoi")}</div>
            <div>{t("Space Jam’s Acoustic")}</div>
            <div>{t("Space Jam là nơi toàn bộ")}</div>
            <div>{t("Toàn bộ khách mời hoạt động")}</div>
          </div>
        </div>
        <div className="list-image">
          <div className="title-image">{t("HÌNH ẢNH")}</div>
          <CCarousel2 items={items} />
        </div>
        <div className="list-project">
          <div className="title">{t("DANH SÁCH DỰ ÁN")}</div>
          <div className="list">
            <div
              className="item-project"
              onClick={() => navigate("/project/ssg-vna")}
            >
              <img alt="ssg" src="/images/PROJECT/ssg-vna.jpg" />
              <div className="title-item">SSG ❤️ VNA</div>
            </div>
            <div
              className="item-project"
              onClick={() => navigate("/project/space-jam-1")}
            >
              <img alt="ssg" src="/images/PROJECT/space-jam.jpg" />
              <div className="title-item">SPACE JAM #1</div>
            </div>
            <div
              className="item-project"
              onClick={() => navigate("/project/muzik-dap-dich")}
            >
              <img alt="ssg" src="/images/PROJECT/muzik-dap-dich.jpg" />
              <div className="title-item">MUZIK DẬP DỊCH</div>
            </div>
            <div
              className="item-project"
              onClick={() => navigate("/project/sstv")}
            >
              <img alt="ssg" src="/images/PROJECT/sstv.jpg" />
              <div className="title-item">SSTV</div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

const items = [
  {
    link: "",
    img: "/images/PROJECT/SPACE-JAM/1.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SPACE-JAM/2.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SPACE-JAM/3.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SPACE-JAM/4.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SPACE-JAM/5.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SPACE-JAM/6.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SPACE-JAM/7.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SPACE-JAM/8.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SPACE-JAM/9.jpeg"
  },
  {
    link: "",
    img: "/images/PROJECT/SPACE-JAM/10.jpeg"
  }
];
export default SPACEJAM;
