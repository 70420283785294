import CCarousel2 from "Common/CCarousel2";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

function BinzSoobin(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="ssg-vna">
      <main>
        {/* <video playsinline="" id="video-cover-top-1" src="https://toquoc.mediacdn.vn/280518851207290880/2022/10/29/cover-videp-01-16669806288781426133725.mp4" muted="" autoplay="" preload="auto" loop="" style={{width:'100%'}} poster="https://toquoc.mediacdn.vn/280518851207290880/2022/10/29/m-layer-1-16669809459322138513824.jpg"></video> */}
        <div className="topic">
          <img
            style={{ width: "100%" }}
            src="https://toquoc.mediacdn.vn/280518851207290880/2023/4/1/low03863-1680359806471953580554.jpg"
          />
            <img
            style={{ width: "100%" }}
            src="https://toquoc.mediacdn.vn/280518851207290880/2023/4/1/low03687-1680359806395978756115.jpg"
          />
          {/* <desc>{t("DỰ ÁN")}</desc>
          <div className="title">{t("eMagazine Exclusive Interview")}</div> */}
          <div className="content">
            <div>{t("binz-soobin1")}</div>
            <br />
            <img
                style={{ width: "100%" }}
                src="https://toquoc.mediacdn.vn/280518851207290880/2023/4/1/low03955-16803599990831979047433.jpg"
            />
            <br />
            <img
                style={{ width: "100%" }}
                src="https://toquoc.mediacdn.vn/280518851207290880/2023/4/1/low03992-168036000470816440634.jpg"
            />
            <div>{t("binz-soobin2")}</div>
            <br />
            <img
                style={{ width: "100%" }}
                src="https://toquoc.mediacdn.vn/280518851207290880/2023/4/1/low04047-1680360086076507198942.jpg"
            />
            <br />
            <div>{t("binz-soobin3")}</div>
            <br />
            <img
                style={{ width: "100%" }}
                src="https://toquoc.mediacdn.vn/280518851207290880/2023/4/1/low04151-16803601330341379249836.jpg"
            />
            <br />
            <div>{t("binz-soobin4")}</div>
            <br />
            <img
                style={{ width: "100%" }}
                src="https://toquoc.mediacdn.vn/280518851207290880/2023/4/1/low04343-1680360177476371069527.jpg"
            />
            <br />
            <div style={{fontWeight:'bold'}}>{t("binz-soobin5")}</div>
            <br />
            <div>{t("binz-soobin6")}</div>
            <br />
            <div>{t("binz-soobin7")}</div>
            <br />
            <img
                style={{ width: "100%" }}
                src="https://kenh14cdn.com/203336854389633024/2023/4/2/photo-2-1680414005663446036592.jpg"
            />
          </div>
        </div>
      </main>
    </div>
  );
}

export default BinzSoobin;
