const content = [
  {
    head: "TRIẾT LÝ HOẠT ĐỘNG",
    des: `SpaceSpeakers là tổ chức âm nhạc được thành lập năm 2011 và không tuân theo bất cứ quy luật nào của thị trường lúc bấy giờ. Trải qua một thập kỉ, “những kẻ lạc loài" nay đã vươn lên thành đế chế dẫn đầu; từ đi ngược thị hiếu, trở thành người định hình thị hiếu. Hành trình đó được xây dựng bằng sự tôn trọng tính khác biệt, và mối liên kết bền chặt giữa mỗi cá nhân trong tổ chức. Đây cũng chính là triết lý cốt lõi của SpaceSpeakers Group và SpaceSpeakers Label: “Tôn trọng sự khác biệt, gắn kết những cá nhân có chung đam mê và mục tiêu”`
  },
  {
    head: "SỨ MỆNH",
    des: "Tạo ra môi trường nơi những cá tính độc đáo trong âm nhạc được rèn dũa và phát triển thành những nhân tố định hình âm nhạc tương lai"
  },
  {
    head: "TẦM NHÌN",
    des: "Tổ chức Hip-Hop lớn nhất tại Việt Nam"
  }
];

export { content };
