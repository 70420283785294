import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import "./styles.scss";

function CCarousel(props) {
  const { children } = props;
  const rootRef = useRef();
  const scrollValue = useRef(0);

  const numSlide = children?.length;
  const flag = useRef(numSlide);

  const [running, setRunning] = useState(false);
  const [slider, setSlider] = useState(() => {
    const elements =
      !_.isEmpty(children) &&
      children?.map((value, key) => ({
        key: "silder-" + key,
        title: "silder " + key,
        component: value,
      }));
    return elements ? [...elements, ...elements, ...elements] : [];
  });

  useEffect(() => {
    const item = rootRef.current.querySelectorAll(".item-c-carousel")[numSlide];
    scrollValue.current = -item?.offsetLeft;
    rootRef.current.style.transform = `translateX(${scrollValue.current}px)`;
  }, []);

  const onMove = (value) => {
    setRunning(true);
    if (running) return;
    rootRef.current.style.transition = `0.4s ease-out`;
    const width =
      rootRef.current.querySelectorAll(".item-c-carousel")[flag.current]
        .offsetWidth;
    scrollValue.current += value * width;
    rootRef.current.style.transform = `translateX(${scrollValue.current}px)`;
    flag.current -= value;

    if (
      flag.current !== numSlide &&
      slider[flag.current] === slider[numSlide]
    ) {
      setSlider((state) => {
        let data = [...state];
        let last = data.splice(0, numSlide);
        data = [...data, ...last];
        return data;
      });
      flag.current = numSlide;
      const item =
        rootRef.current.querySelectorAll(".item-c-carousel")[numSlide];
      scrollValue.current = -item.offsetLeft;
      setTimeout(() => {
        setRunning(false);
        rootRef.current.style.transition = `unset`;
        rootRef.current.style.transform = `translateX(${scrollValue.current}px)`;
      }, 400);
    } else {
      setTimeout(() => {
        setRunning(false);
        rootRef.current.style.transition = `unset`;
      }, 400);
    }
  };

  return (
    <div className="c-carousel-root">
      <div className="arrow-carousel left" onClick={() => onMove(1)}>
        <ion-icon name="chevron-back-outline"></ion-icon>
      </div>

      <div className="c-carousel-main" style={{height: window.innerHeight < 848 ? '80vh' : '100vh'}} ref={rootRef}>
        {slider.map((value, id) => {
          return (
            <div className="item-c-carousel" key={id}>
              {value.component}
            </div>
          );
        })}
      </div>

      <div className="arrow-carousel right" onClick={() => onMove(-1)}>
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </div>

      <div className="list-control">
        {Array.from(Array(numSlide).keys()).map((value) => {
          const slideCur = flag.current % numSlide;
          const active = value === slideCur;
          return (
            <div
              key={value}
              className={active ? "active" : ""}
              onClick={() => onMove(slideCur - value)}
            ></div>
          );
        })}
      </div>
    </div>
  );
}

export default CCarousel;
