import React, { useState, useEffect } from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import CONTENTS from "graphql/getContens.query";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { xoa_dau } from "Common/Util";
import recordData from "./Record/data.js";
import ListRecord from "./listRecord/index";
import axiosInstance from "api/axiosService";

function Discography(props) {
  let navigate = useNavigate();
  const _onDetail = (detailData) => {
    navigate(
      `detail/${detailData.id}`,
      { state: { detailData } }
    );
  };
  const { project } = useParams();
  const [projectDetail, setProjectDetail] = useState({});
  
  const [listArtist, setListArtist] = useState([]);
  // const dataChuck = _.chunk(contentsData, chuck);
  // console.log(dataChuck, "dataChuck");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetchData();
  }, [project]);

  const fetchData = async () => {
    try {
      const projectInfo = await axiosInstance.get(`api/project/info?name=${project}`);
      const info = projectInfo.data.data
      setProjectDetail(info)
      const response = await axiosInstance.get(`api/radarSingle/list?projectId=${info._id}`);
      let tempArtists = []
      response.data.data.forEach(ele => {
        tempArtists.push({
          author: ele.artist,
          name: ele.name,
          image: ele.image,
          url: ele.url,
        })
      })
      setListArtist(tempArtists);
    } catch (error) {
      console.error('Error fetching data: ', error);
      // Xử lý lỗi tại đây
    }
  };

  return (
    <div className="discography-ssg">
        <img width={250} src={projectDetail?.logo}/>
        <h2 style={{color: 'white',width: '50vw', textAlign:'center', paddingTop:'50px'}}>{projectDetail?.name} - {i18n.language === 'en' ? projectDetail?.description_ENG : projectDetail?.description_VN}</h2>
        <h2  style={{color: 'white', textAlign:'center', paddingBottom: '50px'}}>-Powered by SS Label-</h2>
        <div className="discography-all" style={{gap:'50px'}}>
            {listArtist.map((data, index) => {
              return (
                <ListRecord data={data}/>
              );
            })}
        </div>
    </div>
  );
}

export default Discography;
const data_55radar = [
  {
    id: 1,
    author: 'Phickidi',
    name: 'Trên con xe cà tàng',
    image: 'https://i.ytimg.com/vi/zCaIHLTw81s/maxresdefault.jpg',
    url: 'https://youtu.be/zCaIHLTw81s'
  },
  {
    id: 2,
    author: 'Brxlliant',
    name: 'Thuốc Tiên',
    image: 'https://i.ytimg.com/vi/-z-TNDecUd0/maxresdefault.jpg',
    url: 'https://youtu.be/-z-TNDecUd0'
  },
  {
    id: 3,
    author: 'Soulient, Billis, Catchellers',
    name: 'Craven A',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTM5N9UPW4DwqQm69G2qDkzTE-XCgCup4j7V2qLOTP1DQ&s',
    url: 'https://www.youtube.com/watch?v=TRpE3r0WSr8'
  },
  {
    id: 4,
    author: 'Mfree, Gxxfy, Sawty',
    name: "I'm The One",
    image: 'https://i.ytimg.com/vi/xFqVkbL3gdE/sddefault.jpg?v=65fc1883',
    url: 'https://youtu.be/xFqVkbL3gdE'
  },
  {
    id: 5,
    author: 'TraceD, X2C, DatWee',
    name: "Daydayday",
    image: 'https://i.ytimg.com/vi/I4SjAhqYfuo/maxresdefault.jpg',
    url: 'https://youtu.be/I4SjAhqYfuo'
  },
  {
    id: 6,
    author: 'Kriss Ngo',
    name: "Màu sắc",
    image: 'https://i.ytimg.com/vi/CjQ0p7rD31A/maxresdefault.jpg',
    url: 'https://youtu.be/CjQ0p7rD31A'
  },
  {
    id: 7,
    author: 'Jayden, Wensentai',
    name: "Bedroom EP",
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKcNmWYGoi4q0UTu71cNX2tyVfZZ0ZmFI0IBLxlK42cQ&s',
    url: 'https://youtu.be/R1TBWi7NaZk'
  },
  {
    id: 8,
    author: 'Tinle, APJ',
    name: "12h03",
    image: 'https://avatar-ex-swe.nixcdn.com/song/2024/01/01/e/b/f/f/1704116071196_640.jpg',
    url: 'https://youtu.be/epBL9Mbq_sU'
  },
  {
      id: 9,
      author: 'Willistic',
      name: 'Đến Chịu',
      image: 'images/DENCHIU_ARTWORK_VUONG.webp',
      url: 'https://youtu.be/6IZLguQBpSw?si=QCa9JA3LtLMd8FB8'
  },
  {
      id: 10,
      author: 'Ha Kiem ft. NomoVodka',
      name: 'Tiny Room',
      image: 'images/TR_ARTWORK1_VUONG_LOGO.webp',
      url:'https://youtu.be/FTb_Jlm6i2E?si=Y7e4oaf9WRoZfnE1'
  },
  {
    id: 11,
    author: 'NEWA Fee ft. Kriss Ngo',
    name: 'Chắp',
    image: 'images/C_ARTWORK_VUONG2.webp',
    url:'https://youtu.be/dqJmHWd3m8I?si=kiFHC5KfUbhGQaDE'
  },
]
