import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";

function Footer(props) {
  const { t } = useTranslation();
  return (
    <div className="footer-ssg">
      <div className="title">
        <span>{t("SPACESPEAKERS GROUP ALL RIGHTS RESERVED")}.</span>
        <span>{t("UNAUTHORIZED DUPLICATION & RENT IS PROHIBITED")}</span>
      </div>
      <div className="tvo-credit" style={{cursor: "pointer"}}>
        <span>{t("TVOSolution")}</span>
      </div>
    </div>
  );
}

export default Footer;
