import CCarousel from "Common/CCarousel";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import _ from "lodash";

function MUZIK(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="muzik">
      <main>
        <img alt="ssg" src="https://toquoc.mediacdn.vn/280518851207290880/2023/5/22/gig1115-1684722686146525882734.jpg" />
        <div className="topic">
          <desc>{t("DỰ ÁN")}</desc>
          <div className="title">{t("SPACE DATE")}</div>
          <div className="content">
            <div>{t("Space Date-1")}</div>
            <br/>
            <div>{t("Space Date-2")}</div>
            <br/>
            <div>{t("Space Date-3")}</div>
            <div>{t("Space Date-4")}</div>
            <br/>
            <div>{t("Space Date-5")}</div>
          </div>
        </div>
        <img alt="ssg" src="https://toquoc.mediacdn.vn/280518851207290880/2023/5/22/cho-minh-em-va-ok-16847227508531593507849.jpg" />
        <br/>
        {/* <CCarousel>
          {contentsData.map(value => (
            <a
              key={value.id}
              className="thumbnail-item"
              href={value.video}
              target="_blank"
              rel="noreferrer"
            >
              <img
                // onClick={() => setVideo(value)}
                src={_.get(value, "image", "")}
                alt={_.get(value, "image")}
              />
            </a>
          ))}
        </CCarousel> */}
         {/* <div className="list-project">
          <div className="title">{t("DANH SÁCH DỰ ÁN")}</div>
          <div className="list">
            <div
              className="item-project"
              onClick={() => navigate("/project/DoiThatRapThat")}
            >
              <img alt="ssg" src="https://vtv1.mediacdn.vn/thumb_w/650/562122370168008704/2023/6/3/photo1685757404059-16857574053231848538925.png" />
              <div className="title-item">MV Đời Thật Rap Thật</div>
            </div>
            <div
              className="item-project"
              onClick={() => navigate("/project/space-jam-1")}
            >
              <img alt="ssg" src="/images/PROJECT/space-jam.jpg" />
              <div className="title-item">SPACE JAM #1</div>
            </div>
            <div
              className="item-project"
              onClick={() => navigate("/project/muzik-dap-dich")}
            >
              <img alt="ssg" src="/images/PROJECT/muzik-dap-dich.jpg" />
              <div className="title-item">MUZIK DẬP DỊCH</div>
            </div>
            <div
              className="item-project"
              onClick={() => navigate("/project/sstv")}
            >
              <img alt="ssg" src="/images/PROJECT/sstv.jpg" />
              <div className="title-item">SSTV</div>
            </div>
          </div>
        </div> */}
      </main>
    </div>
  );
}

const contentsData = [
  {
    id: 1,
    image:
      "https://i.ytimg.com/vi/5sIMfGjt-Ns/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDkO7z42yrMILUj0MXHa3gf2lmLxg",
    video:
      "https://www.youtube.com/watch?v=5sIMfGjt-Ns&ab_channel=SpaceSpeakersTV"
  },
  {
    id: 2,
    image:
      "https://i.ytimg.com/vi/nkLed2RYMT0/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB55zW6K3QemKOvLoXi9v3x9-dAjQ",
    video:
      "https://www.youtube.com/watch?v=nkLed2RYMT0&ab_channel=SpaceSpeakersTV"
  },
  {
    id: 3,
    image:
      "https://i.ytimg.com/vi/r7XzDQlrl_w/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDrx0wuwDSEUDA7N_EV69vf9rZavQ",
    video:
      "https://www.youtube.com/watch?v=r7XzDQlrl_w&ab_channel=SpaceSpeakersTV"
  },
  {
    id: 4,
    image:
      "https://i.ytimg.com/vi/L1xUkPROKrM/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAq0wk2aOCmT7MdhE8nLjDzIdqF6A",
    video:
      "https://www.youtube.com/watch?v=L1xUkPROKrM&ab_channel=SpaceSpeakersTV"
  },
  {
    id: 5,
    image:
      "https://i.ytimg.com/vi/iMWGpIo8nI4/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD1b3Rn37ROfoIF4jRmbBLEEVxBxw",
    video:
      "https://www.youtube.com/watch?v=iMWGpIo8nI4&ab_channel=SpaceSpeakersTV"
  },
  {
    id: 6,
    image:
      "https://i.ytimg.com/vi/f4mUU2WjU98/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDT43rzU-zDax_-fIy9phmPkqG1SA",
    video:
      "https://www.youtube.com/watch?v=f4mUU2WjU98&ab_channel=SpaceSpeakersTV"
  },
  {
    id: 7,
    image:
      "https://i.ytimg.com/vi/f4mUU2WjU98/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDT43rzU-zDax_-fIy9phmPkqG1SA",
    video:
      "https://www.youtube.com/watch?v=f4mUU2WjU98&ab_channel=SpaceSpeakersTV"
  },
  {
    id: 8,
    image:
      "https://i.ytimg.com/vi/gXifsHGf3Ys/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLADgVSeCxXQeZ6FPoCqCBE6aLKlOw",
    video:
      "https://www.youtube.com/watch?v=gXifsHGf3Ys&ab_channel=SpaceSpeakersTV"
  }
];
export default MUZIK;
