import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import ArtistList from "Components/ArtistList";

function Label(props) {
  const { t } = useTranslation();
  return (
    <div className="label-ssg">
      <div className="title tile-mt">SPACESPEAKERS</div>
      <div className="title">LABEL</div>
      <img
        className="back-ground"
        alt="bg"
        src="https://s01.appfast.me/source/61726d65b5ad6b001dc7419b/myfiles/6446c1261eda47439227c505f0dd872a.jpg"
      />
      <div className="content tile-mt">
        <div className="des">{t("Update Content...")}</div>
      </div>
      <div className="title tile-mt">{t("ARTIST")}</div>
      <ArtistList
        data={[
          {
            src: "/images/person.png",
            name: "ĐANG CẬP NHẬT"
          },
          {
            src: "/images/person.png",
            name: "ĐANG CẬP NHẬT"
          },
          {
            src: "/images/person.png",
            name: "ĐANG CẬP NHẬT"
          }
        ]}
      />
    </div>
  );
}

export default Label;
