import React, { useState } from "react";
import CSSTransitionGroup from "react-addons-css-transition-group";
import { useNavigate } from "react-router-dom";

import "./styles.scss";

function CCarousel2(props) {
  let navigate = useNavigate();
  const [items, setItems] = useState(props.items || []);
  const [loading, setLloading] = useState(false);
  const [active, setActive] = useState(props.active || 0);
  const [direction, setDirection] = useState("");
  const [show, setShow] = useState(false);

  const _onClick = link => {
    alert(link);
    // navigate(link)
    // alert("ok")
  };

  function generateItems() {
    var data = [];
    var level;
    for (var i = active - 2; i < active + 3; i++) {
      const handleClick = e => {
        setShow(e.target.src);
      };
      var index = i;
      if (i < 0) {
        index = items.length + i;
      } else if (i >= items.length) {
        index = i % items.length;
      }
      level = active - i;
      const className = "item level" + level;
      data.push(
        <div className={className} key={index} level={level}>
          {items[index].link ? (
            <a className="content-item" href={items[index].link}>
              <img src={items[index].img} alt="" />
            </a>
          ) : (
            <div className="content-item">
              <img src={items[index].img} alt="" onClick={handleClick} />
            </div>
          )}
        </div>
      );
    }
    return data;
  }

  function moveLeft() {
    if (loading) return;
    setLloading(true);
    setTimeout(() => {
      setLloading(false);
    }, 1000);
    var newActive = active;
    newActive--;
    setActive(newActive < 0 ? items.length - 1 : newActive);
    setDirection("left");
  }

  function moveRight() {
    if (loading) return;
    setLloading(true);
    setTimeout(() => {
      setLloading(false);
    }, 1000);
    setActive((active + 1) % items.length);
    setDirection("right");
  }
  return (
    <div className="c-carousel-2">
      <div className="arrow arrow-left" onClick={moveLeft}>
        <ion-icon name="chevron-back-outline"></ion-icon>
      </div>
      <div id="carousel" className="noselect">
        <CSSTransitionGroup transitionName={direction}>
          {generateItems()}
        </CSSTransitionGroup>
      </div>
      <div className="arrow arrow-right" onClick={moveRight}>
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </div>
      {show && (
        <div className="show-item" onClick={() => setShow(false)}>
          <img src={show} alt="" onClick={e => e.stopPropagation()} />
        </div>
      )}
    </div>
  );
}

export default CCarousel2;
