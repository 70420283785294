import CCarousel from "Common/CCarousel";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import _ from "lodash";

function SSTV(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="SSTV">
      <main>
        <img alt="ssg" src="https://yt3.googleusercontent.com/RVJNlN2Z7gj6A1c9Y9G8COj2LacfICY9zw1i_pxPN02QtvchDuDFR8twmjGvTUC-g82aVzfN=s900-c-k-c0x00ffffff-no-rj" />
        <div className="topic">
          <desc>{t("DỰ ÁN")}</desc>
          <div className="title">{t("MV Dear")}</div>
          <div className="content">
            <div>{t("MV Dear-1")}</div>
            <br />
            <div>{t("MV Dear-2")}</div>
            <br />
            <div>{t("MV Dear-3")}</div>
            <br />
            <div style={{display:'flex'}}>
              <img src="https://media.vov.vn/sites/default/files/styles/large/public/2023-02/dscf4532.jpeg.jpg"/>
              <div>{t("MV Dear-4")}</div>
            </div>
          </div>
        </div>
        {/* <CCarousel>
          {contentsData.map(value => (
            <a
              key={value.id}
              className="thumbnail-item"
              href={value.video}
              target="_blank"
              rel="noreferrer"
            >
              <img
                // onClick={() => setVideo(value)}
                src={_.get(value, "image", "")}
                alt={_.get(value, "image")}
              />
            </a>
          ))}
        </CCarousel>
        <div className="list-project">
          <div className="title">{t("DANH SÁCH DỰ ÁN")}</div>
          <div className="list">
            <div
              className="item-project"
              onClick={() => navigate("/project/ssg-vna")}
            >
              <img alt="ssg" src="/images/PROJECT/ssg-vna.jpg" />
              <div className="title-item">SSG ❤️ VNA</div>
            </div>
            <div
              className="item-project"
              onClick={() => navigate("/project/space-jam-1")}
            >
              <img alt="ssg" src="/images/PROJECT/space-jam.jpg" />
              <div className="title-item">SPACE JAM #1</div>
            </div>
            <div
              className="item-project"
              onClick={() => navigate("/project/muzik-dap-dich")}
            >
              <img alt="ssg" src="/images/PROJECT/muzik-dap-dich.jpg" />
              <div className="title-item">MUZIK DẬP DỊCH</div>
            </div>
            <div
              className="item-project"
              onClick={() => navigate("/project/sstv")}
            >
              <img alt="ssg" src="/images/PROJECT/sstv.jpg" />
              <div className="title-item">SSTV</div>
            </div>
          </div>
        </div> */}
      </main>
    </div>
  );
}

const contentsData = [
  {
    id: 1,
    image:
      "https://i.ytimg.com/vi/MQaZdPB9xdQ/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBIG1jsUKDXKHCHGZxIjO4uih2OGg",
    video:
      "https://www.youtube.com/watch?v=MQaZdPB9xdQ&ab_channel=SpaceSpeakersTV"
  },
  {
    id: 2,
    image:
      "https://i.ytimg.com/vi/6yfMdyULyIg/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA-DMfN0trKmgISup88ksJpKar6nQ",
    video:
      "https://www.youtube.com/watch?v=6yfMdyULyIg&ab_channel=SpaceSpeakersTV"
  },
  {
    id: 3,
    image:
      "https://i.ytimg.com/vi/rIU7U2VRbx4/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBHZcDqTXt-S-NK7fsLNc1nInpAtQ",
    video:
      "https://www.youtube.com/watch?v=rIU7U2VRbx4&ab_channel=SpaceSpeakersTV"
  },
  {
    id: 4,
    image:
      "https://i.ytimg.com/vi/dnBWe_nrOWA/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB2QuSVmkkBVli7yJinJF_LkvbDFA",
    video:
      "https://www.youtube.com/watch?v=dnBWe_nrOWA&ab_channel=SpaceSpeakersTV"
  },
  {
    id: 5,
    image:
      "https://i.ytimg.com/vi/Bl8li_qN20Q/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAfZhTG2zfOQjeyqMlIzZPsvuKSqQ",
    video:
      "https://www.youtube.com/watch?v=Bl8li_qN20Q&ab_channel=SpaceSpeakersTV"
  },
  {
    id: 6,
    image:
      "https://i.ytimg.com/vi/sbzJx5IjEYM/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC4qGLDucwSL-18aSrionTSCIt8aA",
    video:
      "https://www.youtube.com/watch?v=sbzJx5IjEYM&ab_channel=SpaceSpeakersTV"
  },
  {
    id: 7,
    image:
      "https://i.ytimg.com/vi/rTGoD0_ubCE/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBNwz75MXbYdjTUlH9cikB5MC8KHQ",
    video:
      "https://www.youtube.com/watch?v=rTGoD0_ubCE&ab_channel=SpaceSpeakersTV"
  }
];

export default SSTV;
