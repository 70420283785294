import { useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import {
  FaPause,
  FaPlay,
  FaVolumeMute,
  FaVolumeOff,
  FaVolumeUp,
} from "react-icons/fa";
import CONTENTS from "graphql/getContens.query";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { listIsHover, listIsHoverTrue, videoArray } from "./config.js";
import axiosInstance from "api/axiosService";
import ReactPlayer from "react-player";

const styles = {
  width: "150px",
  height: "150px",
  position: "absolute",
  textAlign: "center",
  border: "1px white solid ",
};

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Home(props) {
  const [isHover, setIsHover] = useState(listIsHover);
  const [settingInfo, setSettingInfo] = useState({})
  const [muted, setMuted] = useState(true);

  const vidRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get('/api/setting/info');
      setSettingInfo(response.data.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
      // Xử lý lỗi tại đây
    }
  };


  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setMuted(false); // Bật âm thanh sau một khoảng thời gian, hoặc khi người dùng tương tác
  //   }, 2000); // Ví dụ sau 5 giây

  //   return () => clearTimeout(timer);
  // }, []);

  const handlePlayVideo = () => {
    if (vidRef.current) {
      const iframe = vidRef.current;
      const iframeWindow = iframe.contentWindow;

      // Send the play command to the YouTube iframe
      iframeWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    }
  };

  const handleSoundVideo = (isMute) => {
    vidRef.current.muted = isMute;
    if (vidRef.current) {
      const iframe = vidRef.current;
      const iframeWindow = iframe.contentWindow;

      // Send the play command to the YouTube iframe
      if (isMute) {
        iframeWindow.postMessage('{"method":"setVolume", "value":"0"}', '*');
      } else {
        iframeWindow.postMessage('{"method":"setVolume", "value":"50"}', '*');
      }
    }
  };

  const handlePauseVideo = () => {
    if (vidRef.current) {
      const iframe = vidRef.current;
      const iframeWindow = iframe.contentWindow;

      // Send the pause command to the YouTube iframe
      iframeWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    }
  };

  let navigate = useNavigate();
  const { loading, data } = useQuery(CONTENTS, {
    nextFetchPolicy: "network-only",
    variables: {
      where: {
        projectId: "61726d65b5ad6b001dc7419b",
        source: ["61726d65b5ad6b001dc741aa"],
        type: ["photo", "video", "link", "news", "mp3", "mp4", "pdf"],
      },
    },
  });

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="logo" onClick={() => navigate("/")}>
          <img src={settingInfo?.logo} alt="logo" />
        </div>
      </div>
      {/* <Navbar /> */}
      <div className="home">
        <div id="content">
          <div className="post-grid" style={{ paddingBottom: "360px" }}>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: "0",
                  width: "92vw",
                  height: "72vw",
                  display: "grid",
                  gridTemplateColumns: "20% 20% 20% 20% 20%",
                  zIndex: "100",
                }}
              >
                {settingInfo?.list_video && JSON.parse(settingInfo?.list_video)?.map((ele, index) => {
                  return (
                    <div
                      onClick={() => window.open(ele.linkYouTube)}
                      onMouseEnter={() => {
                        let tempIsHover = [...listIsHover];
                        tempIsHover[index] = true;
                        setIsHover(tempIsHover);
                      }}
                      onMouseLeave={() => {
                        setIsHover(listIsHover);
                      }}
                      style={{
                        padding: "10px",
                        border: "1px solid #2e2e2e",
                        width: "auto",
                        height: "14.2vw",
                        background: isHover[index] ? "#7d7d7d" : "transparent",
                        cursor: "pointer",
                      }}
                    >
                      {isHover[index] ? (
                        <div>
                          <img
                            width={"100%"}
                            // style={{ maxHeight: "150px" }}
                            src={ele.image}
                            alt="#"
                            style={{
                              maxHeight: "17vh",
                            }}
                          />
                          <p
                            className="homepage-card-name"
                            style={{
                              color: "white",
                              fontSize: "18px",
                              fontWeight: "bold",
                              margin: "10px 0",
                            }}
                          >
                            {ele.name}
                          </p>
                        </div>
                      ) : (
                        <div style={{ width: "auto" }}></div>
                      )}
                    </div>
                  );
                })}
              </div>
              {/* <video className='video' ref={vidRef} id="homepage-video"
                style={{ height: '72vw', width: '92vw' }} controls>
                <source src={settingInfo?.video_homepage} type="video/mp4" />
                Trình duyệt của bạn không hỗ trợ thẻ video.
              </video> */}
              <ReactPlayer
                muted
                className='video' ref={vidRef} id="homepage-video"
                style={{ minHeight: '72.4vw', minWidth: '92vw' }} url={settingInfo?.video_homepage}

                playing controls />
            </div>
            <div
              className="video-meta"
              style={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <div className="video-controls-desktop">
                <div
                  className="video-controls"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "40px",
                  }}
                >
                  {/* <div>
                    <FaPlay
                      onClick={() => {
                        handlePlayVideo();
                        setIsHover(listIsHover);
                      }}
                      style={{ color: "white" }}
                      fontSize={"23px"}
                    />
                    <p style={{ color: "white", margin: " 10px 20px" }}>PLAY</p>
                  </div>
                  <div>
                    <FaPause
                      onClick={() => {
                        handlePauseVideo();
                        setIsHover(listIsHoverTrue);
                      }}
                      style={{ color: "white" }}
                      fontSize={"23px"}
                    />
                    <p style={{ color: "white", margin: " 10px 20px" }}>
                      PAUSE
                    </p>
                  </div>
                  <div>
                    {isMuted ? (
                      <FaVolumeMute
                        onClick={() => {
                          handleSoundVideo(false);
                          setIsMuted(false);
                        }}
                        style={{ color: "white" }}
                        fontSize={"23px"}
                      />
                    ) : (
                      <FaVolumeUp
                        onClick={() => {
                          handleSoundVideo(true);
                          setIsMuted(true);
                        }}
                        style={{ color: "white" }}
                        fontSize={"23px"}
                      />
                    )}
                    <p style={{ color: "white", margin: " 10px 20px" }}>
                      SOUND
                    </p>
                  </div> */}
                </div>
                <h3>
                  <a href="https://youtu.be/xdn7sPuE-Sc" target="_blank">
                    {/* KOSMIK LIVE CONCERT RECAP 2022 */}
                  </a>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
